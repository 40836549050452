import { USUARIO_CADASTRADO, USUARIO_LOGADO, USUARIO_DESLOGADO } from "./types";

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case USUARIO_CADASTRADO:
      return action.user;
    case USUARIO_LOGADO:
      return action.user;
    case USUARIO_DESLOGADO:
      return {};
    default:
      return state;
  }
}

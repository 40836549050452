import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Input,
  Icon,
  Button,
  Form,
  Transition,
} from "semantic-ui-react";
import { find, search, create } from "../../../actions/campeonato";
import { find as findCategorias } from "../../../actions/categoria";
import { find as findCompetidores } from "../../../actions/competidor";
import { findByContexto as findStatusByContexto } from "../../../actions/status";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class CadastrarCampeonato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campeonato: { ...props.campeonato },
      file: null,
      errors: {},
      sucesso: false,
      loading: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.stringBusca)
      this.setState({ stringBusca: this.props.match.params.stringBusca });
    await this.props.find(
      this.props.pagina,
      this.props.match.params.stringBusca
    );
    await this.props.findCategorias();
    await this.props.findCompetidores();
    await this.props.findStatusByContexto("Campeonato");
    this.setState({ loading: false });
  };

  onChange = (e) => {
    this.setState({
      campeonato: { ...this.state.campeonato, [e.target.name]: e.target.value },
      errors: {},
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      campeonato: { ...this.state.campeonato, [name]: value },
      errors: {},
    });

  onChangeFile = (e) => {
    const mimeImg = ["image/jpeg", "image/png"];

    if (
      e.target.files &&
      e.target.files[0] &&
      mimeImg.includes(e.target.files[0].type)
    ) {
      this.setState({
        file: e.target.files[0],
        errors: {},
        campeonato: {
          ...this.state.campeonato,
          foto: URL.createObjectURL(e.target.files[0]),
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          foto: "Formato de arquivo inválido. Selecione uma imagem png ou jpeg",
        },
      });
    }
  };

  validate = (campeonato) => {
    const errors = {};
    if (FormValidator.isVazio(campeonato.nome))
      errors.competidor1 = "Nome é obrigatório";
    if (!FormValidator.isDataValida(campeonato.dataInicio))
      errors.dataInicio = "Data Inválida";
    if (!FormValidator.isDataValida(campeonato.dataFim))
      errors.dataFim = "Data Inválida";
    if (!FormValidator.isSelected(campeonato.categoria))
      errors.categoria = "Categoria é obrigatório";
    if (!FormValidator.isSelected(campeonato.status))
      errors.status = "Status é obrigatório";
    if (!this.state.file) errors.foto = "Selecione uma imagem png ou jpeg";
    return errors;
  };

  fileUpload(file) {
    const formData = new FormData();
    formData.append("imagem", file);
    Object.keys(this.state.campeonato).forEach((item) => {
      formData.append(item, this.state.campeonato[item]);
    });
    return formData;
  }

  onSubmitCadastrar = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.campeonato);
    this.setState({ errors });
    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.create(this.fileUpload(this.state.file));
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  getCategorias = () => {
    return this.props.categorias.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  getStatus = () => {
    return this.props.listaStatus.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  render() {
    const { campeonato, errors, loading, sucesso } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Transition
            duration={{ show: 1200, hide: 0 }}
            animation="fade up"
            transitionOnMount={true}
            visible={sucesso}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <Icon name="check circle outline" size="massive" color="green" />
              <br />
              <h3>Campeonato criado com sucesso</h3>
              <br />
              <Button
                as={Link}
                to={`/campeonatos/${encodeURIComponent(campeonato.nome)}`}
                className="ui-btn-1"
              >
                Acessar Página do Campeonato
              </Button>
              <br />
              <br />
              <br />
              <Link to="/dashboard/campeonatos" size="small">
                Voltar
              </Link>
            </div>
          </Transition>
          {!sucesso && (
            <Form
              onSubmit={this.onSubmitCadastrar}
              loading={loading}
              autoComplete={"off"}
            >
              <div className="ui-crud-form">
                <h3>Cadastro de Campeonato</h3>
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                  <Button
                    className="ui-preview-foto"
                    style={{
                      backgroundImage: `url(${campeonato.foto})`,
                      backgroundSize: "cover",
                      opacity: loading ? 0.3 : 1,
                    }}
                    as="label"
                    htmlFor="imagem"
                  >
                    {!this.state.file && (
                      <span>
                        Selecionar Imagem
                        <br />
                        <br />
                        900 x 500
                      </span>
                    )}
                    <Input
                      type="file"
                      id="imagem"
                      name="imagem"
                      style={{ display: "none" }}
                      onChange={this.onChangeFile}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                  </Button>
                  <Erro text={errors.foto} />
                  <br />
                </div>
                <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                  <input
                    className="ui-crud-form-input"
                    name="nome"
                    id="name"
                    type="text"
                    placeholder=""
                    onChange={this.onChange}
                    value={campeonato.nome}
                    required
                  />
                  <label htmlFor="name" className="ui-crud-form-label">Nome</label>
                  <Erro text={errors.nome} />
                </Form.Field>
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.data}>
                    <label htmlFor="dataInicio">Data de início</label>
                    <input
                      id="dataInicio"
                      className="ui-crud-form-input"
                      name="dataInicio"
                      type="date"
                      onChange={this.onChange}
                      value={campeonato.dataInicio}
                      required
                    />
                    <Erro text={errors.dataInicio} />
                  </Form.Field>

                  <Form.Field error={!!errors.data}>
                    <label htmlFor="dataFim">Data do fim</label>
                    <input
                      id="dataFim"
                      className="ui-crud-form-input"
                      name="dataFim"
                      type="date"
                      onChange={this.onChange}
                      value={campeonato.dataFim}
                      required
                    />
                    <Erro text={errors.dataFim} />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.categoria}>
                    <label htmlFor="categoria">Categoria</label>
                    <Form.Select
                      fluid
                      name="categoria"
                      options={this.getCategorias()}
                      onChange={this.onChangeSelect}
                      value={campeonato.categoria._id}
                      placeholder="Categoria"
                    />
                    <Erro text={errors.categoria} />
                  </Form.Field>
                  <Form.Field error={!!errors.status}>
                    <label htmlFor="status">Status</label>
                    <Form.Select
                      fluid
                      name="status"
                      options={this.getStatus()}
                      onChange={this.onChangeSelect}
                      value={campeonato.status}
                      placeholder="Status"
                    />
                    <Erro text={errors.status} />
                  </Form.Field>
                </Form.Group>
                <Form.Field error={!!errors.regulamento} className="ui-crud-form-container" style={{marginTop: "30px"}}>
                  <textarea
                    id="regulamento"
                    className="ui-crud-form-input"
                    name="regulamento"
                    placeholder=""
                    rows={2}
                    value={campeonato.regulamento}
                    onChange={this.onChange}
                    style={{ minHeight: 160 }}
                    required
                  />
                  <label htmlFor="regulamento" className="ui-crud-form-label">Regulamento do Campeonato</label>
                  <Erro text={errors.regulamento} />
                </Form.Field>
                <div style={{ textAlign: "center" }}>
                  <button
                    loading={loading}
                    disabled={loading}
                    className="ui-btn-1 ui-btn-theme-1"
                  >
                    Criar Campeonato
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categorias: state.categoria.categorias,
    listaStatus: state.status.listaStatus,
    campeonato: state.campeonato.campeonato,
    campeonatos: state.campeonato.campeonatos
      ? state.campeonato.campeonatos
      : [],
    quantidadeBusca: state.campeonato.quantidadeBusca,
    quantidade: state.campeonato.quantidade,
    pagina: state.campeonato.pagina,
    errors: state.campeonato.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      find,
      search,
      create,
      findCategorias,
      findStatusByContexto,
      findCompetidores,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastrarCampeonato);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Button,
  Form,
  Dropdown,
  Transition,
  Label,
  Icon,
} from "semantic-ui-react";
import { findById, findByIdAndUpdate } from "../../../actions/competidor";
import { find as findJogadores } from "../../../actions/jogador";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class EditarCompetidor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      competidor: props.competidor,
      errors: {},
      sucesso: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.findById(this.props.match.params.id);
      await this.props.findJogadores();
      this.setState({ competidor: this.props.competidor, loading: false });
    }
  };

  onChange = (e) => {
    this.setState({
      competidor: { ...this.state.competidor, [e.target.name]: e.target.value },
      errors: {},
      sucesso: false,
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      competidor: { ...this.state.competidor, [name]: value },
      errors: {},
      sucesso: false,
    });

  validate = (competidor) => {
    const errors = {};
    if (!competidor.nome) errors.nome = "Nome é obrigatório";
    if (!competidor.jogadores)
      errors.jogadores =
        "Vincule o nickname ou nome da equipe a um jogador ou equipe";
    return errors;
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.competidor);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.competidor._id,
        this.state.competidor
      );
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  getJogadores = () => {
    return this.props.jogadores.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  render() {
    const { competidor, errors, loading, sucesso } = this.state;
    return (
      <div>
        {!loading && !competidor._id && (
          <Redirect to="/dashboard/competidores" />
        )}
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Form
            onSubmit={this.onSubmitEditarDados}
            loading={loading}
            autoComplete={"off"}
          >
            <div className="ui-crud-form">
              <h3>Competidor {competidor.nome}</h3>
              <Transition
                animation={"fade up"}
                duration={1000}
                visible={!!sucesso}
              >
                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                  <Label className="ui-btn-theme-1">
                    <Icon name="check" /> Dados alterados com sucesso!
                  </Label>
                </div>
              </Transition>
              <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                <input 
                  required
                  className="ui-crud-form-input"
                  name="nome"
                  type="text"
                  placeholder=""
                  maxLength="48"
                  onChange={this.onChange}
                  value={competidor.nome}
                />
                <label htmlFor="nome"className="ui-crud-form-label">Nickname ou nome da equipe</label>
                <Erro text={errors.nome} />
              </Form.Field>
              <Form.Field error={!!errors.jogadores}>
                <label htmlFor="status">Jogador ou equipe</label>
                {!loading && (
                  <Dropdown
                    name="jogadores"
                    placeholder="Jogadores"
                    fluid
                    multiple
                    search
                    selection
                    options={this.getJogadores()}
                    noResultsMessage={"Nenhum jogador encontrado"}
                    onChange={this.onChangeSelect}
                    value={competidor.jogadores}
                  />
                )}
                <Erro text={errors.jogadores} />
              </Form.Field>
              <div style={{ textAlign: "center" }}>
                <Button
                  loading={loading}
                  disabled={loading}
                  className="ui-btn-1 ui-btn-theme-1"
                >
                  Editar Competidor
                </Button>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucesso}
                >
                  <div>
                    <div className="ui-div-msg">
                      <br />
                    </div>
                    <Link to="/dashboard/competidores" size="small">
                      Voltar
                    </Link>
                  </div>
                </Transition>
              </div>
            </div>
          </Form>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jogadores: state.jogador.jogadores,
    competidor: state.competidor.competidor,
    errors: state.competidor.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findByIdAndUpdate,
      findJogadores,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCompetidor);

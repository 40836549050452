import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = jogador => ({
  type: 'CREATE_JOGADOR_SUCCESS',
  jogador
});

const findSuccess = (dados) => ({
  type: 'FIND_JOGADORES_SUCCESS',
  dados,
})

const findByIdSuccess = jogador => ({
  type: 'FIND_BY_ID_JOGADOR_SUCCESS',
  jogador
})

const findByIdAndUpdateSuccess = jogador => ({
  type: 'UPDATE_JOGADOR_SUCCESS',
  jogador
})

const addError = errors => ({
  type: 'ADD_JOGADOR_ERROR',
  errors
})

export const create = (dados) => async dispatch => {
  return api.jogador.create(dados).then(data => {
    return dispatch(createSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findById = id => async dispatch => {
  return api.jogador.findById(id).then(data => {
    return dispatch(findByIdSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const find = () => async dispatch => {
  return api.jogador.find().then(data => {
    return dispatch(findSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.jogador.findByIdAndUpdate(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

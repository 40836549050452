import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = (campeonato) => ({
  type: "CREATE_CAMPEONATO_SUCCESS",
  campeonato,
});

const findSuccess = (dados, pagina) => ({
  type: "FIND_CAMPEONATOS_SUCCESS",
  dados,
  pagina,
});

const findByIdSuccess = (campeonato) => ({
  type: "FIND_BY_ID_CAMPEONATO_SUCCESS",
  campeonato,
});

const findByNomeSuccess = (campeonato) => ({
  type: "FIND_BY_NOME_CAMPEONATO_SUCCESS",
  campeonato,
});

const findRelacionadosCategoriaSuccess = (campeonatosRelacionados) => ({
  type: "FIND_REALACIONADOS_CATEGORIA_CAMPEONATO_SUCCESS",
  campeonatosRelacionados,
});

const searchSuccess = (dados, pagina) => ({
  type: "SEARCH_CAMPEONATOS_SUCCESS",
  dados,
  pagina,
});

const findImageByIdAndUpdateSuccess = (campeonato) => ({
  type: "UPDATE_IMAGE_CAMPEONATO_SUCCESS",
  campeonato,
});

const findByIdAndUpdateSuccess = (campeonato) => ({
  type: "UPDATE_CAMPEONATO_SUCCESS",
  campeonato,
});

const addError = (errors) => ({
  type: "ADD_CAMPEONATO_ERROR",
  errors,
});

export const create = (dados) => async (dispatch) => {
  return api.campeonato
    .create(dados)
    .then((data) => {
      return dispatch(createSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findById = (id) => async (dispatch) => {
  return api.campeonato
    .findById(id)
    .then((data) => {
      return dispatch(findByIdSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByNome = (nome) => async (dispatch) => {
  return api.campeonato
    .findByNome(nome)
    .then((data) => {
      return dispatch(findByNomeSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const find = (page, searchString) => async (dispatch) => {
  return api.campeonato
    .find(page, searchString)
    .then((data) => {
      return dispatch(findSuccess(data, page));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const search = (page, searchString) => async (dispatch) => {
  return api.campeonato
    .search(page, searchString)
    .then((data) => {
      return dispatch(searchSuccess(data, page));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findRelacionadosCategoria = (id, categoria) => async (
  dispatch
) => {
  return api.campeonato
    .findRelacionadosCategoria(id, categoria)
    .then((data) => {
      return dispatch(findRelacionadosCategoriaSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findImageByIdAndUpdate = (id, data) => async (dispatch) => {
  return api.campeonato
    .findImageByIdAndUpdate(id, data)
    .then((data) => {
      return dispatch(findImageByIdAndUpdateSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByIdAndUpdate = (id, data) => async (dispatch) => {
  return api.campeonato
    .findByIdAndUpdate(id, data)
    .then((data) => {
      return dispatch(findByIdAndUpdateSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

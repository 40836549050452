import { create } from "apisauce";

const getUrlFormated = (url) => {
  if (url.indexOf("http:") === 0 || url.indexOf("https:") === 0) return url;
  return window.location.protocol === "https:"
    ? `https://${url}`
    : `http://${url}`;
};

const api = create({
  baseURL: getUrlFormated(process.env.REACT_APP_API_URL),
});

api.addAsyncRequestTransform((request) => async () => {
  const token = await localStorage[
    process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN
  ];
  if (token) request.headers["Authorization"] = `Bearer ${token}`;
});

api.addResponseTransform((response) => {
  if (response.status === 401) {
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN);
    window.location.reload();
    localStorage.tokenExpired = "Sua sessão expirou";
  }
  if (!response.ok) throw response;
});

export default {
  usuario: {
    login: (email, senha) =>
      api
        .post(`/auth/login`, { email, senha, web: true })
        .then((res) => res.data),
    confirmar: (token) =>
      api.post(`/confirmacao`, { token }).then((res) => res.data.user),
    getUsuario: () => api.post(`/usuario/`).then((res) => res.data.usuario),
    enviarEmailRecuperacaoSenha: (email) =>
      api.post(`/auth/recuperarSenha`, { email }).then((res) => res.data),
    confirmarCadastro: (token) =>
      api.get(`/auth/confirm_account/${token}`).then((res) => res.data),
    confirmarTokenRecuperacaoSenha: (token) =>
      api
        .post(`/auth/confirmarTokenAlteracaoSenha`, { token })
        .then((res) => res.data),
    recuperarSenha: (data) =>
      api.post(`/auth/reset_password`, { ...data }).then((res) => res.data),
    alterarSenha: (usuario) =>
      api.put(`/usuario/alterarSenha`, { ...usuario }).then((res) => res.data),
    alterarEmail: (usuario) =>
      api.put(`/usuario/alterarEmail`, { ...usuario }).then((res) => res.data),
    alterarDadosPessoais: (usuario) =>
      api
        .put(`/usuario/alterarDadosPessoais`, { ...usuario })
        .then((res) => res.data),
    confirmarTrocaEmail: (token) =>
      api
        .post(`/auth/confirmarTokenAlteracaoEmail`, { token })
        .then((res) => res.data),
    reverterTrocaEmail: (token) =>
      api
        .post(`/auth/confirmarTokenReverterTrocaEmail`, { token })
        .then((res) => res.data),
  },
  email: {
    enviarEmailContato: (dados) =>
      api.post(`/email/contato`, dados).then((res) => res.data),
  },
  partida: {
    create: async (data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.post(`/partida/`, data, config);
      return res.data;
    },
    find: (pagina, stringBusca) => {
      if (stringBusca) {
        return api
          .get(`/partida/pagina/${pagina}/busca/${stringBusca}`)
          .then((res) => res.data);
      }
      return api.get(`/partida/pagina/${pagina}`).then((res) => res.data);
    },
    search: (pagina, stringBusca) =>
      api
        .get(`/partida/pagina/${pagina}/busca/${stringBusca}`)
        .then((res) => res.data),
    findById: (id) => api.get(`/partida/${id}`).then((res) => res.data),
    findByCampeonato: (campeonatoId) =>
      api
        .get(`/partida/campeonato/${campeonatoId}/pagina/1`)
        .then((res) => res.data),
    findRelacionadosCategoria: (id, categoria) =>
      api.get(`/partida/${id}/categoria/${categoria}`).then((res) => res.data),
    findImageByIdAndUpdate: async (id, data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.put(`/partida/image/${id}`, data, config);
      return res.data;
    },
    findByIdAndUpdate: (id, data) =>
      api.put(`/partida/${id}`, data).then((res) => res.data),
    findByIdAndRemove: (id) => {
      api.delete(`/partida/${id}`).then((res) => res.data);
    },
  },
  campeonato: {
    create: async (data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.post(`/campeonato/`, data, config);
      return res.data;
    },
    find: (pagina, stringBusca) => {
      if (stringBusca) {
        return api
          .get(`/campeonato/pagina/${pagina}/busca/${stringBusca}`)
          .then((res) => res.data);
      }
      return api.get(`/campeonato/pagina/${pagina}`).then((res) => res.data);
    },
    search: (pagina, stringBusca) =>
      api
        .get(`/campeonato/pagina/${pagina}/busca/${stringBusca}`)
        .then((res) => res.data),
    findById: (id) => api.get(`/campeonato/${id}`).then((res) => res.data),
    findByNome: (nome) =>
      api.get(`/campeonato/nome/${nome}`).then((res) => res.data),
    findRelacionadosCategoria: (id, categoria) =>
      api
        .get(`/campeonato/${id}/categoria/${categoria}`)
        .then((res) => res.data),
    findImageByIdAndUpdate: async (id, data) => {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const res = await api.put(`/campeonato/image/${id}`, data, config);
      return res.data;
    },
    findByIdAndUpdate: (id, data) =>
      api.put(`/campeonato/${id}`, data).then((res) => res.data),
    findByIdAndRemove: (id) => {
      api.delete(`/campeonato/${id}`).then((res) => res.data);
    },
  },
  jogador: {
    create: (data) => api.post(`/jogador`, data).then((res) => res.data),
    find: () => api.get(`/jogador`).then((res) => res.data),
    findById: (id) => api.get(`/jogador/${id}`).then((res) => res.data),
    findByIdAndUpdate: (id, data) =>
      api.put(`/jogador/${id}`, data).then((res) => res.data),
    findByIdAndRemove: (id) => {
      api.delete(`/jogador/${id}`).then((res) => res.data);
    },
  },
  categoria: {
    create: (data) => api.post(`/categoria`, data).then((res) => res.data),
    find: () => api.get(`/categoria`).then((res) => res.data),
    findById: (id) => api.get(`/categoria/${id}`).then((res) => res.data),
    findByIdAndUpdate: (id, data) =>
      api.put(`/categoria/${id}`, data).then((res) => res.data),
    findByIdAndRemove: (id) =>
      api.delete(`/categoria/${id}`).then((res) => res.data),
  },
  status: {
    create: (data) => api.post(`/status`, data).then((res) => res.data),
    find: () => api.get(`/status`).then((res) => res.data),
    findByContexto: (contexto) =>
      api.get(`/status/contexto/${contexto}`).then((res) => res.data),
    findById: (id) => api.get(`/status/${id}`).then((res) => res.data),
    findByIdAndUpdate: (id, data) =>
      api.put(`/status/${id}`, data).then((res) => res.data),
    findByIdAndRemove: (id) =>
      api.delete(`/status/${id}`).then((res) => res.data),
  },
  competidor: {
    create: (data) => api.post(`/competidor`, data).then((res) => res.data),
    find: () => api.get(`/competidor`).then((res) => res.data),
    findByCampeonato: (campeonatoId) =>
      api.get(`/competidor/campeonato/${campeonatoId}`).then((res) => res.data),
    findById: (id) => api.get(`/competidor/${id}`).then((res) => res.data),
    findByIdAndUpdate: (id, data) =>
      api.put(`/competidor/${id}`, data).then((res) => res.data),
    findByIdAndRemove: (id) => {
      api.delete(`/competidor/${id}`).then((res) => res.data);
    },
  },
};

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import {
  Transition,
  Card,
  Image,
  Button,
  Label,
  Icon,
} from "semantic-ui-react";
moment.locale("pt-BR");

const getFormattedLink = (link) => {
  if (
    String(link).startsWith("http://") ||
    String(link).startsWith("https://")
  ) {
    return link;
  }
  return `http://${link}`;
};

const Partida = ({
  partida,
  className,
  isAuthenticated,
  readOnly,
  history,
}) => {
  // const goTo = (partida) =>
  //   history.push(`/partidas/${encodeURIComponent(partida)}`);
  return (
    <Transition duration={500} animation="fade up" transitionOnMount={true}>
      <Card
        className={readOnly && className ? className : "ui-card-3"}
        // onClick={readOnly ? () => goTo(`${partida._id}`) : null}
        as="div"
      >
        <Image src={partida.foto} wrapped ui={false} className="ui-card-foto" />
        <Card.Content className="ui-card-1-content">
          {!partida.patrocinado && (
            <Card.Header className="ui-card-1-header">
              {partida.competidor1.nome} {"vs"} {partida.competidor2.nome}
            </Card.Header>
          )}
          <Card.Meta>
            {!partida.patrocinado && (
              <p>
                <Icon name="calendar check outline" /> {partida.data}
                &nbsp;&nbsp;&nbsp;
                <Icon name="time" /> {partida.horario}
              </p>
            )}
            <Card.Meta>
              {partida.vencedor && partida.competidor1 && (
                <p>
                  <Icon name="trophy" color="yellow" />
                  &nbsp;&nbsp;
                  {partida.vencedor.nome}
                </p>
              )}
              {!readOnly && isAuthenticated && (
                <span className="date">
                  Criado {moment(partida.createdAt).fromNow()}
                </span>
              )}
            </Card.Meta>
          </Card.Meta>

          {partida.patrocinado && (
            <Card.Description>
              <a
                href={getFormattedLink(partida.link)}
                target="_blank"
                rel="noopener noreferrer"
              >
                Saiba mais
              </a>
            </Card.Description>
          )}
          {readOnly && <Card.Description>{partida.descricao}</Card.Description>}
        </Card.Content>
        <Card.Content extra>
          {!readOnly && isAuthenticated ? (
            <Button
              as={Link}
              to={`/dashboard/partidas/${partida._id}`}
              className="ui-btn-1 ui-btn-theme-1"
              fluid
              size="small"
            >
              Editar
            </Button>
          ) : partida.patrocinado ? (
            <Label color={"black"} horizontal>
              Patrocinado
            </Label>
          ) : (
            <Label color={partida.status.cor} horizontal>
              {partida.status.nome}
            </Label>
          )}
        </Card.Content>
      </Card>
    </Transition>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
  };
}

export default connect(mapStateToProps, {})(Partida);

import React from "react";
// import { Icon } from 'semantic-ui-react';

export const Rodape = () => {
  return (
    <div style={styles.rodape}>
      <p style={styles.creditos}>
        Desenvolvido com{" "}
        <span role="img" aria-label="Emoji Coração">
          ❤️
        </span>
        &nbsp; pela equipe &nbsp;
        <a
          href="https://biolinky.co/otimizesse"
          target="_blank"
          rel="noopener noreferrer"
        >
          OtimiZesse
        </a>
      </p>
      <p style={styles.creditos}>
        <a
          href="/assets/documentos/Política_de_privacidade.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </a>
      </p>
    </div>
      
  );
};

const styles = {
  rodape: {
    width: "100%",
    marginTop: "5px",
    padding: "20px 10px 20px 10px",
    textAlign: "center",
  },
  creditos: {
    fontSize: "9.2pt",
    color: "#666",
    opacity: 0.9,
    padding: "8px",

  },
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Grid,
  Container,
  Icon,
  Loader,
  Placeholder,
  Transition,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { findById } from "../../../actions/campeonato";
import {
  findByCampeonato as findPartidasByCampeonato,
  findRelacionadosCategoria,
} from "../../../actions/partida";
import Partida from "../partida/Partida";
import FormContato from "../../forms/FormContato";
import moment from "moment";
import { Rodape } from "../../layouts/Rodape";

class PaginaCampeonato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  // componentDidUpdate = async (prevProps, prevState) => {
  //   if (prevProps.match.params.nome !== this.props.match.params.nome) {
  //     this.setState({ loading: true });
  //     await this.props.findByNome(this.props.match.params.nome);
  //     if (this.props.partida.categoria)
  //       await this.props.findRelacionadosCategoria(
  //         this.props.partida._id,
  //         this.props.partida.categoria._id
  //       );
  //     this.setState({ loading: false });
  //     scrollToPosition(0);
  //   }
  // };

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      window.scroll(0, 0);
      await this.props.findById(this.props.match.params.id);
      await this.props.findPartidasByCampeonato(this.props.match.params.id);
    } else {
      this.props.history.push("/");
    }
    this.setState({ loading: false });

    document.title = this.props.campeonato.nome;
  };

  componentWillUnmount = () => {
    document.title = "Connect Games Festival";
  };

  render() {
    const { campeonato, partidas, partidasRelacionados } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <div className="corpo-pagina-partida">
          <Container>
            {loading && (
              <div style={{ padding: "120px 20px" }}>
                <Loader active />
              </div>
            )}

            {!loading && campeonato && !campeonato.nome && <Redirect to="/" />}

            <Transition
              visible={!loading}
              animation="fade down"
              duration={{ show: 1200, hide: 0 }}
              transitionOnMount={true}
            >
              <Grid
                verticalAlign="middle"
                stackable
                columns={2}
                className="ui-pagina-partida-section-1"
              >
                <Grid.Column width={8}>
                  <h1>{campeonato.nome}</h1>
                  {!!(campeonato.vencedor || {})._id && (
                    <h2>
                      <Icon name="trophy" color="yellow" />
                      &nbsp;&nbsp;
                      {campeonato.vencedor.nome}
                    </h2>
                  )}
                  <br />
                  <h3>
                    <Icon name="tag" /> {campeonato.categoria.nome}
                  </h3>
                  <br />
                  <h3>
                    <Icon name="calendar alternate outline" />{" "}
                    {moment(campeonato.dataInicio).format("DD/MM/YYYY")}
                    &nbsp; a &nbsp;
                    {moment(campeonato.dataFim).format("DD/MM/YYYY")}
                  </h3>
                </Grid.Column>
                <Grid.Column width={8} className="ui-img-pagina-partida">
                  <img src={campeonato.foto} alt={campeonato.nome} />
                </Grid.Column>
              </Grid>
            </Transition>
          </Container>
        </div>
        <div className="ui-home-section-2" id="partidas">
          <Container>
            <h2 style={{ marginBottom: "30px" }}>Partidas</h2>
            <Grid stackable columns={4} doubling>
              {!!partidas.length &&
                partidas.map((partida) => (
                  <Grid.Column key={partida._id}>
                    <Partida
                      partida={partida}
                      className="ui-card-1"
                      readOnly={true}
                      {...this.props}
                    />
                  </Grid.Column>
                ))}
            </Grid>
          </Container>
        </div>
        <div className="ui-pagina-partida-section-2" id="partidas">
          <Container>
            <Grid verticalAlign="middle" stackable columns={2}>
              <Grid.Column width={16}>
                <div style={{ marginBottom: "40px" }}>
                  <h2 style={{ marginLeft: "-5px" }}>
                    <Icon name="file alternate outline" /> Regulamento
                  </h2>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <div className="ui-pagina-partida-section-3">
          <Container>
            {!loading ? (
              !!partidasRelacionados.length ? (
                <div style={{ textAlign: "center" }}>
                  <h2>Outros campeonatos de {campeonato.categoria.nome}</h2>
                  {partidasRelacionados.map((partida) => (
                    <Button
                      key={campeonato._id}
                      style={{ margin: "10px" }}
                      as={Link}
                      to={`/partidas/${partida.nome}`}
                      className="ui-btn-1"
                      size="small"
                    >
                      {campeonato.nome}
                    </Button>
                  ))}
                </div>
              ) : (
                <h3 className="ui-home-section-4" id="regulamento">
                  {loading ? (
                    <Placeholder style={{ marginTop: "20px" }}>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  ) : (
                    <p>{campeonato.regulamento}</p>
                  )}
                </h3>
              )
            ) : (
              <div style={{ padding: "60px 20px" }}>
                <Loader active inline="centered" />
              </div>
            )}
          </Container>
        </div>

        <div className="ui-home-section-4" id="contato">
          <Container>
            {/* <h2>
              Quero saber mais sobre o compeonato{" "}
              <span>
                {campeonato.nome} e outros conteúdos do Connect Games Festival!{" "}
              </span>
            </h2> */}
            <Grid
              stackable
              columns={2}
              centered
              divided
              verticalAlign="middle"
              style={{ marginTop: "40px" }}
            >
              <Grid.Column>
                <div className="ui-div-section-4">
                  <a
                    href="https://linktr.ee/connectgamesfestival"
                    rel="noopener noreferrer"
                    className="ui-social-whatsapp"
                    target="_blank"
                  >
                    <Icon name="share alternate" />
                  </a>
                  <h3>
                    Chama a gente nas redes sociais!{" "}
                    <span role="img" aria-label="Emoji Oculos Escuro">
                      😎
                    </span>
                  </h3>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-div-section-4">
                  <h3>Ou se preferir, envie-nos um email</h3>
                  <br />
                  {!!campeonato.nome && (
                    <FormContato
                      mensagem={`Olá, tenho interesse em saber mais sobre ${campeonato.nome} e outros conteúdos do Connect Games Festival`}
                    />
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>

        <div className="ui-rodape">
          <p>
            <b>Connect Games Festival</b>
          </p>
          <p>Juiz de Fora - MG</p>
          
          <br />
          <p>
            <a
              href="https://www.facebook.com/Connect-Games-Festival-100227815146398"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="facebook" size="big" />
            </a>
            &nbsp;
            <a
              href="https://www.instagram.com/connectgamesf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="instagram" size="big" />
            </a>
            &nbsp;
            <a
              href="https://www.linkedin.com/company/jf-festival/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" size="big" />
            </a>
            <a
              href="https://twitter.com/connectgamesf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="twitter" size="big" />
            </a>
            <Rodape />
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    campeonato: state.campeonato.campeonato,
    partidas: state.partida.partidas,
    partidasRelacionados: state.partida.partidasRelacionados,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { findById, findPartidasByCampeonato, findRelacionadosCategoria },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaginaCampeonato);

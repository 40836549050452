// Auth
export const USUARIO_CADASTRADO = "USUARIO_CADASTRADO";
export const USUARIO_LOGADO = "USUARIO_LOGADO";
export const USUARIO_DESLOGADO = "USUARIO_DESLOGADO";
export const EMAIL_RECUPERACAO_SENHA_ENVIADO =
  "EMAIL_RECUPERACAO_SENHA_ENVIADO";
export const CADASTRO_USUARIO_CONFIRMADO = "CADASTRO_USUARIO_CONFIRMADO";
export const TOKEN_RECUPERACAO_SENHA_CONFIRMADO =
  "TOKEN_RECUPERACAO_SENHA_CONFIRMADO";
export const SENHA_RECUPERADA = "SENHA_RECUPERADA";
export const TOKEN_CONFIRMACAO_TROCA_EMAIL_CONFIRMADO =
  "TOKEN_CONFIRMACAO_TROCA_EMAIL_CONFIRMADO";
export const TOKEN_REVERTER_TROCA_EMAIL_CONFIRMADO =
  "TOKEN_REVERTER_TROCA_EMAIL_CONFIRMADO";

//Usuario
export const DADOS_PESSOAIS_ALTERADO = "DADOS_PESSOAIS_ALTERADO";
export const EMAIL_ALTERADO = "EMAIL_ALTERADO";
export const SENHA_ALTERADA = "SENHA_ALTERADA";

//Sistema
export const EMAIL_CONTATO_ENVIADO = "EMAIL_CONTATO_ENVIADO";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Input,
  Button,
  Form,
  Transition,
  Label,
  Icon,
  Message,
} from "semantic-ui-react";
import {
  findById,
  findImageByIdAndUpdate,
  findByIdAndUpdate,
} from "../../../actions/campeonato";
import { find as findCategorias } from "../../../actions/categoria";
import { findByCampeonato as findCompetidoresByCampeonato } from "../../../actions/competidor";
import { findByContexto as findStatusByContexto } from "../../../actions/status";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Textarea from "react-textarea-autosize";
import Erro from "../../layouts/messages/Erro";
import moment from "moment";

class EditarCampeonato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campeonato: { ...this.props.campeonato },
      file: null,
      errors: {},
      sucesso: false,
      sucessoFoto: false,
      loading: true,
      loadingFoto: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.findById(this.props.match.params.id);
      await this.props.findCategorias();
      await this.props.findCompetidoresByCampeonato(this.props.match.params.id);
      await this.props.findStatusByContexto("Campeonato");
      this.setState({ campeonato: this.props.campeonato, loading: false });
    }
  };

  onChange = (e) => {
    this.setState({
      campeonato: { ...this.state.campeonato, [e.target.name]: e.target.value },
      errors: {},
      sucesso: false,
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      campeonato: { ...this.state.campeonato, [name]: value },
      errors: {},
      sucesso: false,
    });

  onChangeObjectSelect = (e, { name, value }) =>
    this.setState({
      campeonato: {
        ...this.state.campeonato,
        [name]: {
          ...[name],
          _id: value,
        },
      },
      errors: {},
      sucesso: false,
    });

  onChangeFile = (e) => {
    const mimeImg = ["image/jpeg", "image/png"];

    if (
      e.target.files &&
      e.target.files[0] &&
      mimeImg.indexOf(e.target.files[0].type) > -1
    ) {
      this.setState({
        file: e.target.files[0],
        errors: {},
        campeonato: {
          ...this.state.campeonato,
          foto: URL.createObjectURL(e.target.files[0]),
        },
      });
    }
  };

  validateFoto = (file) => {
    const errors = {};
    if (!file) errors.file = "Selecione uma imagem png ou jpeg";
    return errors;
  };

  validate = (campeonato) => {
    const errors = {};
    if (FormValidator.isVazio(campeonato.nome))
      errors.competidor1 = "Nome é obrigatório";
    if (FormValidator.isVazio(campeonato.dataInicio))
      errors.dataInicio = "Data Inválida";
    if (FormValidator.isVazio(campeonato.dataFim))
      errors.dataFim = "Data Inválida";
    if (!FormValidator.isSelected(campeonato.categoria))
      errors.categoria = "Categoria é obrigatório";
    if (!FormValidator.isSelected(campeonato.status))
      errors.status = "Status é obrigatório";
    return errors;
  };

  fileUpload(file) {
    const formData = new FormData();
    formData.append("imagem", file);
    Object.keys(this.state.campeonato).forEach((item) => {
      formData.append(item, this.state.campeonato[item]);
    });
    return formData;
  }

  getStatus = () => {
    return this.props.listaStatus.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  getCategorias = () => {
    return this.props.categorias.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  getCompetidores = () => {
    return [
      {
        key: null,
        value: null,
        text: "Selecione um vencedor",
      },
      ...this.props.competidores.map((competidor) => {
        return {
          key: competidor._id,
          value: competidor._id,
          text: competidor.nome,
        };
      }),
    ];
  };

  onSubmitEditarFoto = async (e) => {
    e.preventDefault();
    const errors = this.validateFoto(this.state.file);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loadingFoto: true });

      await this.props.findImageByIdAndUpdate(
        this.state.campeonato._id,
        this.fileUpload(this.state.file)
      );
      this.setState({
        loadingFoto: false,
        file: null,
        sucessoFoto: true,
      });
    }
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.campeonato);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.campeonato._id,
        this.state.campeonato
      );
      this.setState({
        loading: false,
        sucesso: Object.keys(this.props.errors).length === 0,
        errors: this.props.errors,
      });
    }
  };
  render() {
    const {
      campeonato,
      errors,
      loading,
      loadingFoto,
      file,
      sucesso,
      sucessoFoto,
    } = this.state;
    return (
      <div>
        {!loading && !campeonato._id && (
          <Redirect to="/dashboard/campeonatos" />
        )}
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <div className="ui-crud-form">
            <h3>Editando campeonato de {campeonato.nome}</h3>
            <Form
              onSubmit={this.onSubmitEditarFoto}
              loading={loading}
              autoComplete={"off"}
            >
              <div style={{ textAlign: "center" }}>
                <Button
                  className="ui-preview-foto"
                  style={{
                    backgroundImage: `url(${campeonato.foto})`,
                    marginBottom: "25px",
                    backgroundSize: "cover",
                    opacity: loading ? 0.3 : 1,
                  }}
                  as="label"
                  htmlFor="imagem"
                >
                  {!this.state.file && (
                    <span>
                      Selecionar Imagem
                      <br />
                      <br />
                      900 x 500
                    </span>
                  )}
                  <Input
                    type="file"
                    id="imagem"
                    name="imagem"
                    style={{ display: "none" }}
                    onChange={this.onChangeFile}
                    accept="image/png, image/jpeg, image/jpg"
                    disabled={!!(this.props.campeonato.vencedor || {})._id}
                  />
                </Button>
                <br />
                <Transition
                  visible={!!file}
                  duration={{ show: 1000, hide: 0 }}
                  animation="fade"
                  unmountOnHide={true}
                >
                  <div className="ui-div-msg">
                    <Button
                      disabled={
                        loadingFoto ||
                        loading ||
                        !!(this.props.campeonato.vencedor || {})._id
                      }
                      loading={loadingFoto}
                      size="small"
                      className="ui-btn-1 ui-btn-theme-1"
                      onClick={this.onSubmitEditarFoto}
                    >
                      Salvar
                    </Button>
                  </div>
                </Transition>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucessoFoto}
                >
                  <div className="ui-div-msg">
                    <Label className="ui-btn-theme-1">
                      <Icon name="photo" /> Imagem alterada com sucesso!
                    </Label>
                  </div>
                </Transition>
                <Erro text={errors.foto} />
                <br />
              </div>
            </Form>
            <Form
              onSubmit={this.onSubmitEditarDados}
              loading={loading}
              autoComplete={"off"}
            >
              {!(this.props.campeonato.vencedor || {})._id && (
                <Form.Field error={!!errors.categoria}>
                  <label htmlFor="categoria">Vencedor</label>
                  <Form.Select
                    fluid
                    name="vencedor"
                    options={this.getCompetidores()}
                    onChange={this.onChangeObjectSelect}
                    value={campeonato.vencedor._id}
                    placeholder="Vencedor"
                  />
                  <Erro text={errors.categoria} />
                </Form.Field>
              )}
              <Message color="blue">
                {!!(this.props.campeonato.vencedor || {})._id ? (
                  <div>
                    <p>
                      <strong>Campeonato finalizado.</strong>
                    </p>
                    <span>
                      <Icon name="trophy" />{" "}
                      {this.props.campeonato.vencedor.nome}
                    </span>
                  </div>
                ) : (
                  `Após selecionar um vencedor, o campeonato será finalizado e não poderá ser feito nenhuma alteração.`
                )}
              </Message>
              <Form.Field error={!!errors.nome}>
                <label htmlFor="nome">Nome</label>
                <Input
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  onChange={this.onChange}
                  value={campeonato.nome}
                  autoFocus
                  disabled={!!campeonato.vencedor._id}
                />
                <Erro text={errors.nome} />
              </Form.Field>
              <Form.Group widths={2}>
                <Form.Field error={!!errors.data}>
                  <label htmlFor="dataInicio">Data de início</label>
                  <Input
                    name="dataInicio"
                    type="date"
                    onChange={this.onChange}
                    value={moment(campeonato.dataInicio).format("YYYY-MM-DD")}
                    disabled={!!campeonato.vencedor._id}
                  />
                  <Erro text={errors.dataInicio} />
                </Form.Field>

                <Form.Field error={!!errors.data}>
                  <label htmlFor="dataFim">Data do fim</label>
                  <Input
                    name="dataFim"
                    type="date"
                    onChange={this.onChange}
                    value={moment(campeonato.dataFim).format("YYYY-MM-DD")}
                    disabled={!!campeonato.vencedor._id}
                  />
                  <Erro text={errors.dataFim} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={2}>
                <Form.Field error={!!errors.categoria}>
                  <label htmlFor="categoria">Categoria</label>
                  <Form.Select
                    fluid
                    name="categoria"
                    options={this.getCategorias()}
                    onChange={this.onChangeObjectSelect}
                    value={campeonato.categoria._id}
                    placeholder="Categoria"
                    disabled={!!campeonato.vencedor._id}
                  />
                  <Erro text={errors.categoria} />
                </Form.Field>
                <Form.Field error={!!errors.status}>
                  <label htmlFor="status">Status</label>
                  <Form.Select
                    fluid
                    name="status"
                    options={this.getStatus()}
                    onChange={this.onChangeObjectSelect}
                    value={campeonato.status._id}
                    placeholder="Status"
                    disabled={!!campeonato.vencedor._id}
                  />
                  <Erro text={errors.status} />
                </Form.Field>
              </Form.Group>
              <Form.Field error={!!errors.regulamento}>
                <label htmlFor="regulamento">Regulamento</label>
                <Textarea
                  name="regulamento"
                  placeholder="Regulamento do campeonato"
                  rows={2}
                  value={campeonato.regulamento}
                  onChange={this.onChange}
                  style={{ minHeight: 160 }}
                  disabled={!!campeonato.vencedor._id}
                />
                <Erro text={errors.regulamento} />
              </Form.Field>
              <div style={{ textAlign: "center" }}>
                <Button
                  loading={loading}
                  disabled={
                    loading || !!(this.props.campeonato.vencedor || {})._id
                  }
                  className="ui-btn-1 ui-btn-theme-1"
                  onSubmit={this.onSubmitEditarDados}
                >
                  Editar Campeonato
                </Button>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucesso}
                >
                  <div>
                    <div className="ui-div-msg">
                      <Label className="ui-btn-theme-1">
                        <Icon name="check" /> Dados alterados com sucesso!
                      </Label>
                      <br />
                    </div>
                    <Link to="/dashboard/campeonatos" size="small">
                      Voltar
                    </Link>
                  </div>
                </Transition>
              </div>
            </Form>
          </div>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categorias: state.categoria.categorias,
    listaStatus: state.status.listaStatus,
    competidores: state.competidor.competidores,
    campeonatos: [], // MOCK
    campeonato: state.campeonato.campeonato,
    errors: state.campeonato.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findImageByIdAndUpdate,
      findByIdAndUpdate,
      findCategorias,
      findStatusByContexto,
      findCompetidoresByCampeonato,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCampeonato);

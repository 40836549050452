import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = (partida) => ({
  type: "CREATE_PARTIDA_SUCCESS",
  partida,
});

const findSuccess = (dados, pagina) => ({
  type: "FIND_PARTIDAS_SUCCESS",
  dados,
  pagina,
});

const findByIdSuccess = (partida) => ({
  type: "FIND_BY_ID_PARTIDA_SUCCESS",
  partida,
});

const findByCampeonatoSuccess = (data) => ({
  type: "FIND_BY_CAMPEONATO_PARTIDA_SUCCESS",
  data,
});

const findRelacionadosCategoriaSuccess = (partidasRelacionados) => ({
  type: "FIND_REALACIONADOS_CATEGORIA_PARTIDA_SUCCESS",
  partidasRelacionados,
});

const searchSuccess = (dados, pagina) => ({
  type: "SEARCH_PARTIDAS_SUCCESS",
  dados,
  pagina,
});

const findImageByIdAndUpdateSuccess = (partida) => ({
  type: "UPDATE_IMAGE_PARTIDA_SUCCESS",
  partida,
});

const findByIdAndUpdateSuccess = (partida) => ({
  type: "UPDATE_PARTIDA_SUCCESS",
  partida,
});

const addError = (errors) => ({
  type: "ADD_PARTIDA_ERROR",
  errors,
});

export const create = (dados) => async (dispatch) => {
  return api.partida
    .create(dados)
    .then((data) => {
      return dispatch(createSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findById = (id) => async (dispatch) => {
  return api.partida
    .findById(id)
    .then((data) => {
      return dispatch(findByIdSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByCampeonato = (campeonadoId) => async (dispatch) => {
  return api.partida
    .findByCampeonato(campeonadoId)
    .then((data) => {
      return dispatch(findByCampeonatoSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const find = (page, searchString) => async (dispatch) => {
  return api.partida
    .find(page, searchString)
    .then((data) => {
      return dispatch(findSuccess(data, page));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const search = (page, searchString) => async (dispatch) => {
  return api.partida
    .search(page, searchString)
    .then((data) => {
      return dispatch(searchSuccess(data, page));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findRelacionadosCategoria = (id, categoria) => async (
  dispatch
) => {
  return api.partida
    .findRelacionadosCategoria(id, categoria)
    .then((data) => {
      return dispatch(findRelacionadosCategoriaSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findImageByIdAndUpdate = (id, data) => async (dispatch) => {
  return api.partida
    .findImageByIdAndUpdate(id, data)
    .then((data) => {
      return dispatch(findImageByIdAndUpdateSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByIdAndUpdate = (id, data) => async (dispatch) => {
  return api.partida
    .findByIdAndUpdate(id, data)
    .then((data) => {
      return dispatch(findByIdAndUpdateSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

const initState = {
  partida: {
    nome: "",
    competidor1: {},
    competidor2: {},
    descricao: "",
    data: "",
    horario: "",
    status: "",
    foto: "",
    categoria: "",
    campeonato: {},
    link: "",
    patrocinado: false,
  },
  partidas: [],
  partidasRelacionados: [],
  quantidade: 0,
  quantidadeBusca: 0,
  pagina: 1,
  errors: {},
};

const partida = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_PARTIDAS_SUCCESS":
      return {
        ...initState,
        pagina: action.pagina,
        partidas:
          action.pagina === 1
            ? action.dados.partidas
            : [...state.partidas, ...action.dados.partidas],
        quantidade: action.dados.quantidade,
        quantidadeBusca: action.dados.quantidade,
      };
    case "SEARCH_PARTIDAS_SUCCESS":
      return {
        ...state,
        partida: initState.partida,
        partidas: action.dados.partidas,
        quantidadeBusca: action.dados.quantidade,
      };
    case "FIND_REALACIONADOS_CATEGORIA_PARTIDA_SUCCESS":
      return {
        ...state,
        partidasRelacionados: action.partidasRelacionados,
      };
    case "CREATE_PARTIDA_SUCCESS":
      return {
        ...initState,
        partidas: [action.partida, ...state.partidas],
        quantidade: state.quantidade + 1,
      };
    case "FIND_BY_ID_PARTIDA_SUCCESS":
      return {
        ...initState,
        partida: { ...state.partida, ...action.partida },
      };
    case "FIND_BY_CAMPEONATO_PARTIDA_SUCCESS":
      return {
        ...initState,
        partidas: [...action.data.partidas],
        quantidade: action.data.quantidade,
      };
    case "QUANTIDADE_PARTIDAS_SUCCESS":
      return {
        ...state,
        quantidade: action.dados.quantidade,
      };
    case "UPDATE_PARTIDA_SUCCESS":
      return {
        ...initState,
        quantidade: state.quantidade,
        quantidadeBusca: state.quantidadeBusca,
        partidas: state.partidas.map((partida) => {
          if (partida._id === action.partida._id) return action.partida;
          return partida;
        }),
      };
    case "UPDATE_IMAGE_PARTIDA_SUCCESS":
      return {
        ...initState,
        quantidade: state.quantidade,
        quantidadeBusca: state.quantidadeBusca,
        partidas: state.partidas.map((partida) => {
          if (partida._id === action.partida._id) return action.partida;
          return partida;
        }),
      };
    case "ADD_PARTIDA_ERROR":
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default partida;

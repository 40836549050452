const initState = {
  jogador: {
    nome: "",
    email: "",
    cpf: "",
    instagram: "",
    twitter: "",
    endereco: {
      pais: "",
      estado: "",
      cidade: "",
      cep: "",
      rua: "",
      bairro: "",
      numero: "",
      complemento: "",
      telefone: "",
    },
    categorias: []
  },
  jogadores: [],
  quantidade: 0,
  errors: {}
}

const jogador = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_JOGADORES_SUCCESS':
      return {
        ...initState,
        jogadores: action.dados.jogadores,
        quantidade: action.dados.quantidade,
      };
    case 'CREATE_JOGADOR_SUCCESS':
      return {
        ...initState,
        jogadores: [action.jogador, ...state.jogadores],
        quantidade: state.quantidade + 1,
      };
    case 'FIND_BY_ID_JOGADOR_SUCCESS':
      return {
        ...initState,
        jogador: { ...state.jogador, ...action.jogador }
      };
    case 'QUANTIDADE_JOGADORES_SUCCESS':
      return {
        ...state,
        quantidade: action.dados.quantidade
      };
    case 'UPDATE_JOGADOR_SUCCESS':
      return {
        ...initState,
        quantidade: state.quantidade,
        jogadores: state.jogadores.map(jogador => {
          if (jogador._id === action.jogador._id)
            return action.jogador;
          return jogador;
        })
      };
    case 'ADD_JOGADOR_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

export default jogador;
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Form,
  Transition,
  Label,
  Icon,
} from "semantic-ui-react";
import { findById, findByIdAndUpdate } from "../../../actions/categoria";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class EditarCategoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: props.categoria,
      errors: {},
      sucesso: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    await this.props.findById(this.props.match.params.id);
    this.setState({
      categoria: { ...this.props.categoria },
      loading: false,
    });
  };

  onChange = (e) => {
    this.setState({
      categoria: { ...this.state.categoria, [e.target.name]: e.target.value },
      errors: {},
      sucesso: false,
    });
  };

  validate = (categoria) => {
    const errors = {};
    if (FormValidator.isVazio(categoria.nome))
      errors.nome = "Nome é obrigatório";
    return errors;
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.categoria);
    this.setState({ errors });
    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.categoria._id,
        this.state.categoria
      );
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  render() {
    const { categoria, errors, loading, sucesso } = this.state;
    return (
      <div>
        {!loading && !categoria._id && <Redirect to="/dashboard/categorias" />}
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Form
            onSubmit={this.onSubmitEditarDados}
            loading={loading}
            autoComplete={"off"}
          >
            <div className="ui-crud-form">
              <h3>Categoria {categoria.nome}</h3>
              <Transition
                animation={"fade up"}
                duration={1000}
                visible={!!sucesso}
              >
                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                  <Label className="ui-btn-theme-1">
                    <Icon name="check" /> Dados alterados com sucesso!
                  </Label>
                </div>
              </Transition>
              <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                <input
                  className="ui-crud-form-input"
                  required
                  name="nome"
                  type="text"
                  placeholder=""
                  maxLength="48"
                  onChange={this.onChange}
                  value={categoria.nome}
                />
                <label htmlFor="nome" className="ui-crud-form-label">Nome</label>
                <Erro text={errors.nome} />
              </Form.Field>
              <div style={{ textAlign: "center" }}>
                <button
                  loading={loading}
                  disabled={loading}
                  className="ui-btn-1 ui-btn-theme-1"
                >
                  Editar Categoria
                </button>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucesso}
                >
                  <div>
                    <div className="ui-div-msg">
                      <br />
                    </div>
                    <Link to="/dashboard/categorias" size="small">
                      Voltar
                    </Link>
                  </div>
                </Transition>
              </div>
            </div>
          </Form>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoria: state.categoria.categoria,
    errors: state.categoria.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findByIdAndUpdate,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCategoria);

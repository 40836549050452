import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Input,
  Button,
  Form,
  Transition,
  Label,
  Icon,
  Checkbox,
  Message
} from "semantic-ui-react";
import {
  findById,
  findImageByIdAndUpdate,
  findByIdAndUpdate,
} from "../../../actions/partida";
import { find as findCompetidores } from "../../../actions/competidor";
import { find as findCampeonatos } from "../../../actions/campeonato";
import { findByContexto as findStatusByContexto } from "../../../actions/status";

import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Textarea from "react-textarea-autosize";
import Erro from "../../layouts/messages/Erro";

class EditarPartida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partida: { ...this.props.partida },
      file: null,
      errors: {},
      sucesso: false,
      sucessoFoto: false,
      loading: true,
      loadingFoto: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.findById(this.props.match.params.id);
      await this.props.findCompetidores();
      await this.props.findStatusByContexto("Partida");
      await this.props.findCampeonatos(1);
      this.setState({ partida: this.props.partida, loading: false });
    }
  };

  onChange = (e) => {
    this.setState({
      partida: { ...this.state.partida, [e.target.name]: e.target.value },
      errors: {},
      sucesso: false,
    });
  };

  onChangeBoolean = (e, { name, checked }) => {
    e.preventDefault();
    this.setState({
      partida: { ...this.state.partida, [name]: checked },
      errors: {},
      sucesso: false,
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      partida: { ...this.state.partida, [name]: value },
      errors: {},
      sucesso: false,
    });

  onChangeObjectSelect = (e, { name, value }) =>
    this.setState({
      partida: {
        ...this.state.partida,
        [name]: {
          ...[name],
          _id: value,
        },
      },
      errors: {},
      sucesso: false,
    });

  onChangeFile = (e) => {
    const mimeImg = ["image/jpeg", "image/png"];

    if (
      e.target.files &&
      e.target.files[0] &&
      mimeImg.indexOf(e.target.files[0].type) > -1
    ) {
      this.setState({
        file: e.target.files[0],
        errors: {},
        partida: {
          ...this.state.partida,
          foto: URL.createObjectURL(e.target.files[0]),
        },
      });
    }
  };

  validateFoto = (file) => {
    const errors = {};
    if (!file) errors.file = "Selecione uma imagem png ou jpeg";
    return errors;
  };

  validate = (partida) => {
    const errors = {};

    if (!FormValidator.isSelected(partida.campeonato))
      errors.campeonato = "Campeonato é obrigatório";

    if (partida.patrocinado) {
      if (!FormValidator.isUrl(partida.link))
        errors.link = "Formato de URL inválido. (Exemplo válido: www.site.com)";
    } else {
      if (FormValidator.isVazio(partida.competidor1))
        errors.competidor = "Competidor 1 é obrigatório";
      if (FormValidator.isVazio(partida.competidor2))
        errors.competidor = "Competidor 2 é obrigatório";
      if (!FormValidator.isDataValida(partida.data))
        errors.data = "Data Inválida";
      if (FormValidator.isVazio(partida.horario))
        errors.horario = "Horário Inválido";
      if (!FormValidator.isSelected(partida.status))
        errors.status = "Status é obrigatório";
    }

    return errors;
  };

  fileUpload(file) {
    const formData = new FormData();
    formData.append("imagem", file);
    Object.keys(this.state.partida).forEach((item) => {
      formData.append(item, this.state.partida[item]);
    });
    return formData;
  }

  getCompetidores = () => {
    return [
      {
        key: null,
        value: null,
        text: "Selecione um vencedor",
      },
      ...this.props.competidores.map((competidor) => {
        return {
          key: competidor._id,
          value: competidor._id,
          text: competidor.nome,
        };
      }),
    ];
  };

  getCampeonatos = () => {
    return this.props.campeonatos.map((campeonato) => {
      return {
        key: campeonato._id,
        value: campeonato._id,
        text: campeonato.nome,
      };
    });
  };

  getStatus = () => {
    return this.props.listaStatus.map((status) => {
      return {
        key: status._id,
        value: status._id,
        text: status.nome,
      };
    });
  };

  onSubmitEditarFoto = async (e) => {
    e.preventDefault();
    const errors = this.validateFoto(this.state.file);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loadingFoto: true });

      await this.props.findImageByIdAndUpdate(
        this.state.partida._id,
        this.fileUpload(this.state.file)
      );
      this.setState({
        loadingFoto: false,
        file: null,
        sucessoFoto: true,
      });
    }
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.partida);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.partida._id,
        this.state.partida
      );
      this.setState({
        loading: false,
        sucesso: Object.keys(this.props.errors).length === 0,
        errors: this.props.errors,
      });
    }
  };

  getValueFromObject = (object) => {
    return !object ? {} : object._id;
  };

  render() {
    const {
      partida,
      errors,
      loading,
      loadingFoto,
      file,
      sucesso,
      sucessoFoto,
    } = this.state;

    return (
      <div>
        {!loading && !partida._id && <Redirect to="/dashboard/partidas" />}
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <div className="ui-crud-form">
            <h3>Editando partida</h3>
            <Form
              onSubmit={this.onSubmitEditarFoto}
              loading={loading}
              autoComplete={"off"}
            >
              <div style={{ textAlign: "center" }}>
                <Button
                  className="ui-preview-foto"
                  style={{
                    backgroundImage: `url(${partida.foto})`,
                    marginBottom: "25px",
                    backgroundSize: "cover",
                    opacity: loading ? 0.3 : 1,
                  }}
                  as="label"
                  htmlFor="imagem"
                >
                  {!this.state.file && (
                    <span>
                      Selecionar Imagem
                      <br />
                      <br />
                      900 x 500
                    </span>
                  )}
                  <Input
                    type="file"
                    id="imagem"
                    name="imagem"
                    style={{ display: "none" }}
                    onChange={this.onChangeFile}
                    accept="image/png, image/jpeg, image/jpg"
                    disabled={!!(this.props.partida.vencedor || {})._id}
                  />
                </Button>
                <br />
                <Transition
                  visible={!!file}
                  duration={{ show: 1000, hide: 0 }}
                  animation="fade"
                  unmountOnHide={true}
                >
                  <div className="ui-div-msg">
                    <Button
                      disabled={
                        loadingFoto || 
                        loading 
                      }
                      loading={loadingFoto}
                      size="small"
                      className="ui-btn-1 ui-btn-theme-1"
                      onClick={this.onSubmitEditarFoto}
                    >
                      Salvar
                    </Button>
                  </div>
                </Transition>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucessoFoto}
                >
                  <div className="ui-div-msg">
                    <Label className="ui-btn-theme-1">
                      <Icon name="photo" /> Imagem alterada com sucesso!
                    </Label>
                  </div>
                </Transition>
                <Erro text={errors.foto} />
                <br />
              </div>
            </Form>
            <Form
              onSubmit={this.onSubmitEditarDados}
              loading={loading}
              autoComplete={"off"}
            >
              {!(this.props.partida.vencedor || {})._id && (
                <Form.Field error={!!errors.vencedor}>
                  <label htmlFor="vencedor">Vencedor</label>
                  <Form.Select
                    fluid
                    name="vencedor"
                    options={this.getCompetidores()}
                    onChange={this.onChangeObjectSelect}
                    value={this.getValueFromObject(partida.vencedor) || {}}
                    placeholder="Vencedor"
                  />
                  <Erro text={errors.vencedor} />
                </Form.Field>
              )}
              <Message color="blue">
                {!!(this.props.partida.vencedor || {})._id ? (
                  <div>
                    <p>
                      <strong>Partida finalizada.</strong>
                    </p>
                    <span>
                      <Icon name="trophy" />{" "}
                      {this.props.partida.vencedor.nome}
                    </span>
                  </div>
                ) : (
                  `Após selecionar um vencedor, a partida será finalizada e não poderá ser feita nenhuma alteração.`
                )}
              </Message>
              <Form.Field error={!!errors.patrocinado}>
                <label htmlFor="patrocinado">Patrocinado</label>
                <Checkbox
                  name="patrocinado"
                  onChange={this.onChangeBoolean}
                  toggle
                  checked={partida.patrocinado}
                  disabled={!!loading || !!(this.props.partida.vencedor || {})._id}
                />
                <Erro text={errors.patrocinado} />
              </Form.Field>
              <Form.Field error={!!errors.campeonato}>
                <label htmlFor="campeonato">Campeonato</label>
                <Form.Select
                  fluid
                  name="campeonato"
                  options={this.getCampeonatos()}
                  onChange={this.onChangeObjectSelect}
                  value={this.getValueFromObject(partida.campeonato)}
                  placeholder="Campeonato"
                />
                <Erro text={errors.campeonato} />
              </Form.Field>

              {!partida.patrocinado && (
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.competidor1}>
                    <label htmlFor="nome">Competidor 1</label>
                    <Form.Select
                      fluid
                      name="competidor1"
                      options={this.getCompetidores()}
                      onChange={this.onChangeObjectSelect}
                      value={this.getValueFromObject(partida.competidor1)}
                      placeholder="Competidor 1"
                    />
                    <Erro text={errors.competidor1} />
                  </Form.Field>
                  <Form.Field error={!!errors.competidor2}>
                    <label htmlFor="nome">Competidor 2</label>
                    <Form.Select
                      fluid
                      name="competidor2"
                      options={this.getCompetidores()}
                      onChange={this.onChangeObjectSelect}
                      value={this.getValueFromObject(partida.competidor2)}
                      placeholder="Competidor 2"
                    />
                    <Erro text={errors.competidor2} />
                  </Form.Field>
                </Form.Group>
              )}

              {partida.patrocinado && (
                <Form.Field error={!!errors.link}>
                  <label htmlFor="link">Link do anúncio</label>
                  <Input
                    name="link"
                    type="text"
                    placeholder="www.site.com/anuncio"
                    onChange={this.onChange}
                    value={partida.link}
                    autoFocus
                  />
                  <Erro text={errors.link} />
                </Form.Field>
              )}
              {!partida.patrocinado && (
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.data}>
                    <label htmlFor="data">Data</label>
                    <Input
                      name="data"
                      type="date"
                      min="1"
                      step="1"
                      onChange={this.onChange}
                      value={partida.data}
                    />
                    <Erro text={errors.data} />
                  </Form.Field>

                  <Form.Field error={!!errors.horario}>
                    <label htmlFor="horario">Horário</label>
                    <Input
                      name="horario"
                      type="time"
                      min="1"
                      step="1"
                      onChange={this.onChange}
                      value={partida.horario}
                    />
                    <Erro text={errors.horario} />
                  </Form.Field>
                </Form.Group>
              )}

              {!partida.patrocinado && (
                <Form.Field error={!!errors.status}>
                  <label htmlFor="status">Status</label>
                  <Form.Select
                    fluid
                    name="status"
                    options={this.getStatus()}
                    onChange={this.onChangeSelect}
                    value={this.getValueFromObject(partida.status)}
                    placeholder="Status"
                  />
                  <Erro text={errors.status} />
                </Form.Field>
              )}

              <Form.Field error={!!errors.descricao}>
                {partida.patrocinado ? (
                  <label htmlFor="status">Descrição do anúncio</label>
                ) : (
                  <label htmlFor="status">Detalhes da partida</label>
                )}

                <Textarea
                  name="descricao"
                  placeholder={
                    partida.patrocinado
                      ? "Descrição do anúncio..."
                      : "Detalhes da partida..."
                  }
                  rows={2}
                  value={partida.descricao}
                  onChange={this.onChange}
                  style={{ minHeight: 120 }}
                />
                <Erro text={errors.descricao} />
              </Form.Field>
              <div style={{ textAlign: "center" }}>
                <Button
                  loading={loading}
                  disabled={loading}
                  className="ui-btn-1 ui-btn-theme-1"
                  onSubmit={this.onSubmitEditarDados}
                >
                  Editar Partida
                </Button>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucesso}
                >
                  <div>
                    <div className="ui-div-msg">
                      <Label className="ui-btn-theme-1">
                        <Icon name="check" /> Dados alterados com sucesso!
                      </Label>
                      <br />
                    </div>
                    <Link to="/dashboard/partidas" size="small">
                      Voltar
                    </Link>
                  </div>
                </Transition>
              </div>
            </Form>
          </div>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    competidores: state.competidor.competidores,
    listaStatus: state.status.listaStatus,
    campeonatos: state.campeonato.campeonatos,
    partida: state.partida.partida,
    errors: state.partida.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findImageByIdAndUpdate,
      findByIdAndUpdate,
      findCompetidores,
      findStatusByContexto,
      findCampeonatos
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarPartida);

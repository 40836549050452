import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Button,
  Form,
  Dropdown,
  Transition,
  Label,
  Icon,
} from "semantic-ui-react";
import { findById, findByIdAndUpdate } from "../../../actions/jogador";
import { find as findCategorias } from "../../../actions/categoria";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class EditarJogador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jogador: props.jogador,
      errors: {},
      sucesso: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.findById(this.props.match.params.id);
      await this.props.findCategorias();
      this.setState({ jogador: this.props.jogador, loading: false });
    }
  };

  onChange = (e) => {
    this.setState({
      jogador: { ...this.state.jogador, [e.target.name]: e.target.value },
      errors: {},
      sucesso: false,
    });
  };

  onChangeEndereco = (e) => {
    this.setState({
      jogador: {
        ...this.state.jogador,
        endereco: {
          ...this.state.jogador.endereco,
          [e.target.name]: e.target.value,
        },
      },
      errors: {},
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      jogador: { ...this.state.jogador, [name]: value },
      errors: {},
      sucesso: false,
    });

  validate = (jogador) => {
    const errors = {};
    if (!jogador.nome) errors.nome = "Nome é obrigatório";
    return errors;
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.jogador);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.jogador._id,
        this.state.jogador
      );
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  getCategorias = () => {
    return this.props.categorias.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  render() {
    const { jogador, errors, loading, sucesso } = this.state;
    return (
      <div>
        {!loading && !jogador._id && <Redirect to="/dashboard/jogadores" />}
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Form
            onSubmit={this.onSubmitEditarDados}
            loading={loading}
            autoComplete={"off"}
          >
            <div className="ui-crud-form">
              <h3>Jogador {jogador.nome}</h3>
              <Transition
                animation={"fade up"}
                duration={1000}
                visible={!!sucesso}
              >
                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                  <Label className="ui-btn-theme-1">
                    <Icon name="check" /> Dados alterados com sucesso!
                  </Label>
                </div>
              </Transition>
              <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                  <input
                    className="ui-crud-form-input"
                    name="nome"
                    type="text"
                    placeholder=""
                    onChange={this.onChange}
                    value={jogador.nome}
                    autoFocus
                    required
                  />
                  <label htmlFor="nome" className="ui-crud-form-label">Nome</label>
                  <Erro text={errors.nome} />
                </Form.Field>
                <Form.Field error={!!errors.email} className="ui-crud-form-container" >
                  <input
                    className="ui-crud-form-input"
                    required
                    name="email"
                    type="text"
                    placeholder=""
                    onChange={this.onChange}
                    value={jogador.email}
                    autoFocus
                  />
                  <label htmlFor="email" className="ui-crud-form-label">Email</label>
                  <Erro text={errors.email} />
                </Form.Field>
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.cpf} className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      required
                      name="cpf"
                      type="text"
                      placeholder=""
                      onChange={this.onChange}
                      value={jogador.cpf}
                    />
                    <label htmlFor="cpf" className="ui-crud-form-label">CPF</label>
                    <Erro text={errors.cpf} />
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="instagram"
                      type="text"
                      placeholder=""
                      onChange={this.onChange}
                      value={jogador.instagram}
                    />
                    <label htmlFor="instagram" className="ui-crud-form-label">Instagram</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="twitter"
                      type="text"
                      placeholder=""
                      onChange={this.onChange}
                      value={jogador.twitter}
                    />
                    <label htmlFor="twitter"className="ui-crud-form-label" >Twitter</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="pais"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.pais}
                    />
                    <label htmlFor="pais"className="ui-crud-form-label">Pais</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="estado"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.estado}
                    />
                    <label htmlFor="estado"className="ui-crud-form-label">Estado</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="cidade"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.cidade}
                    />
                    <label htmlFor="cidade"className="ui-crud-form-label">Cidade</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="cep"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.cep}
                    />
                    <label htmlFor="cep"className="ui-crud-form-label">Cep</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="numero"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.numero}
                    />
                    <label htmlFor="numero"className="ui-crud-form-label">Número</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="bairro"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.bairro}
                    />
                    <label htmlFor="bairro"className="ui-crud-form-label">Bairro</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="rua"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.rua}
                    />
                    <label htmlFor="rua"className="ui-crud-form-label">Rua</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="complemento"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.complemento}
                    />
                    <label htmlFor="complemento"className="ui-crud-form-label">Complemento</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      required
                      className="ui-crud-form-input"
                      name="telefone"
                      type="text"
                      placeholder=""
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.telefone}
                    />
                    <label htmlFor="telefone"className="ui-crud-form-label">Telefone</label>
                  </Form.Field>
                  </Form.Group>
                <Form.Field error={!!errors.categorias}>
                  <label htmlFor="status">Categorias</label>
                  {!loading &&
                    <Dropdown
                      name='categorias'
                      placeholder='Categorias'
                      fluid
                      multiple
                      search
                      selection
                      options={this.getCategorias()}
                      noResultsMessage={"Nenhum categoria encontrada"}
                      onChange={this.onChangeSelect}
                      value={jogador.categorias}
                    />
                  }
                  <Erro text={errors.categorias} />
                </Form.Field>
              <div style={{ textAlign: "center" }}>
                <Button
                  loading={loading}
                  disabled={loading}
                  className="ui-btn-1 ui-btn-theme-1"
                >
                  Editar Jogador
                </Button>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucesso}
                >
                  <div>
                    <div className="ui-div-msg">
                      <br />
                    </div>
                    <Link to="/dashboard/jogadores" size="small">
                      Voltar
                    </Link>
                  </div>
                </Transition>
              </div>
            </div>
          </Form>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categorias: state.categoria.categorias,
    jogador: state.jogador.jogador,
    errors: state.jogador.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findByIdAndUpdate,
      findCategorias,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarJogador);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Form,
  Transition,
  Label,
  Icon,
} from "semantic-ui-react";
import { findById, findByIdAndUpdate } from "../../../actions/status";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class EditarStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      errors: {},
      sucesso: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    await this.props.findById(this.props.match.params.id);
    this.setState({
      status: { ...this.props.status },
      loading: false,
    });
  };

  onChange = (e) => {
    this.setState({
      status: { ...this.state.status, [e.target.name]: e.target.value },
      errors: {},
      sucesso: false,
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      status: { ...this.state.status, [name]: value },
      errors: {},
    });

  validate = (status) => {
    const errors = {};
    if (FormValidator.isVazio(status.nome)) errors.nome = "Nome é obrigatório";
    return errors;
  };

  onSubmitEditarDados = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.status);
    this.setState({ errors });
    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.findByIdAndUpdate(
        this.state.status._id,
        this.state.status
      );
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  getColors = () => [
    {
      key: "black",
      value: "black",
      text: "Preto",
      label: { color: "black", empty: true, circular: true },
    },
    {
      key: "orange",
      value: "orange",
      text: "Laranja",
      label: { color: "orange", empty: true, circular: true },
    },
    {
      key: "grey",
      value: "grey",
      text: "Cinza",
      label: { color: "grey", empty: true, circular: true },
    },
    {
      key: "red",
      value: "red",
      text: "Vermelho",
      label: { color: "red", empty: true, circular: true },
    },
    {
      key: "yellow",
      value: "yellow",
      text: "Amarelo",
      label: { color: "yellow", empty: true, circular: true },
    },
    {
      key: "olive",
      value: "olive",
      text: "Oliva",
      label: { color: "olive", empty: true, circular: true },
    },
    {
      key: "green",
      value: "green",
      text: "Verde",
      label: { color: "green", empty: true, circular: true },
    },
    {
      key: "blue",
      value: "blue",
      text: "Azul",
      label: { color: "blue", empty: true, circular: true },
    },
    {
      key: "teal",
      value: "teal",
      text: "Azul-petróleo",
      label: { color: "teal", empty: true, circular: true },
    },
    {
      key: "violet",
      value: "violet",
      text: "Violeta",
      label: { color: "violet", empty: true, circular: true },
    },
    {
      key: "purple",
      value: "purple",
      text: "Roxo",
      label: { color: "purple", empty: true, circular: true },
    },
    {
      key: "pink",
      value: "pink",
      text: "Rosa",
      label: { color: "pink", empty: true, circular: true },
    },
    {
      key: "brown",
      value: "brown",
      text: "Marrom",
      label: { color: "brown", empty: true, circular: true },
    },
  ];

  render() {
    const { status, errors, loading, sucesso } = this.state;
    return (
      <div>
        {!loading && !status._id && <Redirect to="/dashboard/status" />}
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Form
            onSubmit={this.onSubmitEditarDados}
            loading={loading}
            autoComplete={"off"}
          >
            <div className="ui-crud-form">
              <h3>Status {status.nome}</h3>
              <Transition
                animation={"fade up"}
                duration={1000}
                visible={!!sucesso}
              >
                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                  <Label className="ui-btn-theme-1">
                    <Icon name="check" /> Dados alterados com sucesso!
                  </Label>
                </div>
              </Transition>
              <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                <input
                  className="ui-crud-form-input"
                  required
                  name="nome"
                  type="text"
                  placeholder=""
                  maxLength="48"
                  onChange={this.onChange}
                  value={status.nome}
                  autoFocus
                />
                <label htmlFor="nome" className="ui-crud-form-label">Nome</label>
                <Erro text={errors.nome} />
              </Form.Field>
              <Form.Field error={!!errors.contexto} className="ui-crud-form-container">
                <input 
                  required
                  className="ui-crud-form-input"
                  name="contexto"
                  type="text"
                  placeholder=""
                  maxLength="48"
                  onChange={this.onChange}
                  value={status.contexto}
                />
                <label htmlFor="contexto"className="ui-crud-form-label">Contexto</label>
                <Erro text={errors.contexto} />
              </Form.Field>
              <Form.Field error={!!errors.cor}>
                <label htmlFor="cor">Cor</label>
                <Form.Select
                  fluid
                  name="cor"
                  options={this.getColors()}
                  onChange={this.onChangeSelect}
                  value={status.cor}
                  placeholder="Cor"
                />
                <Erro text={errors.cor} />
              </Form.Field>
              <div style={{ textAlign: "center" }}>
                <button
                  loading={loading}
                  disabled={loading}
                  className="ui-btn-1 ui-btn-theme-1"
                >
                  Editar Status
                </button>
                <Transition
                  animation={"fade up"}
                  duration={1000}
                  visible={!!sucesso}
                >
                  <div>
                    <div className="ui-div-msg">
                      <br />
                    </div>
                    <Link to="/dashboard/status" size="small">
                      Voltar
                    </Link>
                  </div>
                </Transition>
              </div>
            </div>
          </Form>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.status.status,
    errors: state.status.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      findById,
      findByIdAndUpdate,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarStatus);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Grid,
  Icon,
  Divider,
  Button,
  Transition,
} from "semantic-ui-react";
import { find, search } from "../../../actions/campeonato";
import { Rodape } from "../../layouts/Rodape";
import Campeonato from "./Campeonato";

class Campeonatos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
    };
  }

  componentWillMount = async () => {
    if (this.props.match.params.stringBusca)
      this.setState({ stringBusca: this.props.match.params.stringBusca });
    await this.props.find(
      this.props.pagina,
      this.props.match.params.stringBusca
    );
    this.setState({ loading: false });
  };

  render() {
    const { campeonatos } = this.props;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <div style={{ textAlign: "center", marginBottom: "35px" }}>
            <Button
              className="ui-btn-1"
              as={Link}
              to={"/dashboard/campeonatos/cadastrar"}
            >
              <Icon name="plus" /> Adicionar Campeonato
            </Button>
            <Transition>
              <div>
                <div className="ui-div-msg">
                  <br />
                </div>
                <Link to="/dashboard" size="small">
                  Voltar
                </Link>
              </div>
            </Transition>
          </div>
          <Divider horizontal>Campeonatos</Divider>
          <Grid columns={4} doubling stackable style={{ marginTop: "20px" }}>
            {!!campeonatos.length &&
              campeonatos.map((campeonato) => (
                <Grid.Column key={campeonato._id}>
                  <Campeonato campeonato={campeonato} />
                </Grid.Column>
              ))}
          </Grid>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campeonatos: state.campeonato.campeonatos
      ? state.campeonato.campeonatos
      : [],
    quantidadeBusca: state.campeonato.quantidadeBusca,
    quantidade: state.campeonato.quantidade,
    pagina: state.campeonato.pagina,
    error: state.campeonato.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      find,
      search,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Campeonatos);

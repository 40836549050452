import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Grid,
  Icon,
  Divider,
  Button,
  Transition,
} from "semantic-ui-react";
import { find } from "../../../actions/jogador";
import { Rodape } from "../../layouts/Rodape";
import Jogador from "./Jogador";

class Jogadores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
    };
  }

  componentWillMount = async () => {
    await this.props.find();
    this.setState({ loading: false });
  };

  render() {
    const { jogadores } = this.props;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <div style={{ textAlign: "center", marginBottom: "35px" }}>
            <Button
              className="ui-btn-1"
              as={Link}
              to={"/dashboard/jogadores/cadastrar"}
            >
              <Icon name="plus" /> Adicionar Jogador
            </Button>
            <Transition>
              <div>
                <div className="ui-div-msg">
                  <br />
                </div>
                <Link to="/dashboard" size="small">
                  Voltar
                </Link>
              </div>
            </Transition>
          </div>
          <Divider horizontal>Jogadores</Divider>
          <Grid columns={4} doubling stackable style={{ marginTop: "20px" }}>
            {!!jogadores.length &&
              jogadores.map((jogador) => (
                <Grid.Column key={jogador._id}>
                  <Jogador jogador={jogador} />
                </Grid.Column>
              ))}
          </Grid>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jogadores: state.jogador.jogadores,
    quantidade: state.jogador.quantidade,
    error: state.jogador.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      find,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Jogadores);

import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt-br';
import { Transition, Card, Button } from 'semantic-ui-react';
moment.locale('pt-BR');

const Jogador = ({ jogador, className, isAuthenticated, readOnly, history }) => {
  const goTo = jogador => history.push(`/jogadores/${encodeURIComponent(jogador)}`);
  return (
    <Transition duration={500} animation="fade up" transitionOnMount={true}>
      <Card
        className={(readOnly && className) ? className : "ui-card-3"}
        onClick={readOnly ? () => goTo(`${jogador.nome}`) : null}
        as="div"
      >
        <Card.Content className="ui-card-1-content-2">
          <Card.Header className="ui-card-1-header">{jogador.nome}</Card.Header>
          <Card.Meta>
            {!readOnly && isAuthenticated ?
              <span className='date'>Criado {moment(jogador.createdAt).fromNow()}</span>
              :
              false
            }
          </Card.Meta>
        </Card.Content>
        <Card.Content extra>
          {(!readOnly && isAuthenticated) ?
            <Button as={Link} to={`/dashboard/jogadores/${jogador._id}`} className="ui-btn-1 ui-btn-theme-1" fluid size="small">Editar</Button>
            :
            "Jogador"
          }
        </Card.Content>
      </Card>
    </Transition>
  )
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token
  };
}

export default connect(mapStateToProps, {})(Jogador);
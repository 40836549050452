import api from "../api";
import errorHandler from "../utils/errorHandler";

export const sendEmailContatoSuccess = data => ({
  type: 'SEND_EMAIL_CONTATO_SUCCESS',
  data
});

const addError = errors => ({
  type: 'ADD_EMAIL_ERROR',
  errors
})

export const enviarEmailContato = dados => async dispatch => {
  return api.email.enviarEmailContato(dados).then(data => {
    return dispatch(sendEmailContatoSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}
import React from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const Erro404 = (props) => {
  return (
    <div style={{ padding: "50px 20px", textAlign: "center" }}>
      <Container>
        <div className="logo-pagina-erro">
          <img
            src="/assets/imagens/logo1.png"
            alt="Connect Games Festival Logo"
          />
        </div>
        <br />
        <h2>Página não encontrada</h2>
        <br />
        <br />
        <Button
          as={Link}
          to={`/`}
          className="ui-btn-1 ui-btn-theme-1"
          size="small"
        >
          Voltar para página inicial
        </Button>
      </Container>
    </div>
  );
};

export default Erro404;

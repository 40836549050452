import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import { Container, Grid, Card, Icon } from "semantic-ui-react";
import { Rodape } from "../../layouts/Rodape";

class Dashboard extends Component {
  render() {
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "30px" }}>
          <Grid columns={4} doubling stackable>
            <Grid.Column>
              <CardUI to="/dashboard/campeonatos">
                <Card.Content>
                  <Card.Header>
                    <Icon name="trophy" size="large" />
                  </Card.Header>
                  <Card.Description className="ui-card-description-dashboard">
                    <h3>Campeonatos</h3>
                  </Card.Description>
                </Card.Content>
              </CardUI>
            </Grid.Column>
            <Grid.Column>
              <CardUI to="/dashboard/partidas">
                <Card.Content>
                  <Card.Header>
                    <Icon name="play" size="large" />
                  </Card.Header>
                  <Card.Description className="ui-card-description-dashboard">
                    <h3>Partidas</h3>
                  </Card.Description>
                </Card.Content>
              </CardUI>
            </Grid.Column>
            <Grid.Column>
              <CardUI to="/dashboard/jogadores">
                <Card.Content>
                  <Card.Header>
                    <Icon name="group" size="large" />
                  </Card.Header>
                  <Card.Description className="ui-card-description-dashboard">
                    <h3>Jogadores</h3>
                  </Card.Description>
                </Card.Content>
              </CardUI>
            </Grid.Column>
            <Grid.Column>
              <CardUI to="/dashboard/competidores">
                <Card.Content>
                  <Card.Header>
                    <Icon name="flag checkered" size="large" />
                  </Card.Header>
                  <Card.Description className="ui-card-description-dashboard">
                    <h3>Competidores</h3>
                  </Card.Description>
                </Card.Content>
              </CardUI>
            </Grid.Column>
            <Grid.Column>
              <CardUI to="/dashboard/categorias">
                <Card.Content>
                  <Card.Header>
                    <Icon name="game" size="large" />
                  </Card.Header>
                  <Card.Description className="ui-card-description-dashboard">
                    <h3>Categorias</h3>
                  </Card.Description>
                </Card.Content>
              </CardUI>
            </Grid.Column>
            <Grid.Column>
              <CardUI to="/dashboard/status">
                <Card.Content>
                  <Card.Header>
                    <Icon name="tag" size="large" />
                  </Card.Header>
                  <Card.Description className="ui-card-description-dashboard">
                    <h3>Status</h3>
                  </Card.Description>
                </Card.Content>
              </CardUI>
            </Grid.Column>
          </Grid>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const CardUI = ({ children, to }) => {
  return (
    <Card as={Link} to={to} className="ui-card-dashboard">
      {children}
    </Card>
  );
};

export default connect(null, {})(Dashboard);

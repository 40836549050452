const errorHandler = {
  getError: error => {
    if (error.data)
      return error.data.errors;
    if (error.problem === "NETWORK_ERROR")
      return "Não foi possível conectar ao servidor";
    if (error.status === 429)
      return "Aguarde alguns minutos e tente novamente";
    return "Não foi possível completar essa ação no momento"
  }
}

export default errorHandler;
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Grid,
  Icon,
  Divider,
  Button,
  Transition,
} from "semantic-ui-react";
import { find } from "../../../actions/status";
import { Rodape } from "../../layouts/Rodape";
import Status from "./Status";

class ListaStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
    };
  }

  componentDidMount = async () => {
    await this.props.find();
    this.setState({ loading: false });
  };

  render() {
    const { listaStatus } = this.props;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <div style={{ textAlign: "center", marginBottom: "35px" }}>
            <Button
              className="ui-btn-1"
              as={Link}
              to={"/dashboard/status/cadastrar"}
            >
              <Icon name="plus" /> Adicionar Status
            </Button>
            <Transition>
              <div>
                <div className="ui-div-msg">
                  <br />
                </div>
                <Link to="/dashboard" size="small">
                  Voltar
                </Link>
              </div>
            </Transition>
          </div>
          <Divider horizontal>Listar status</Divider>
          <Grid columns={4} doubling stackable style={{ marginTop: "20px" }}>
            {!!listaStatus.length &&
              listaStatus.map((status) => (
                <Grid.Column key={status._id}>
                  <Status status={status} />
                </Grid.Column>
              ))}
          </Grid>
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listaStatus: state.status.listaStatus,
    quantidade: state.status.quantidade,
    errors: state.status.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      find,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaStatus);

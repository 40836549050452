import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = categoria => ({
  type: 'CREATE_CATEGORIA_SUCCESS',
  categoria
});

const findSuccess = (dados) => ({
  type: 'FIND_CATEGORIAS_SUCCESS',
  dados,
})

const findByIdSuccess = categoria => ({
  type: 'FIND_BY_ID_CATEGORIA_SUCCESS',
  categoria
})

const findByIdAndUpdateSuccess = categoria => ({
  type: 'UPDATE_CATEGORIA_SUCCESS',
  categoria
})

const addError = errors => ({
  type: 'ADD_CATEGORIA_ERROR',
  errors
})

export const create = (dados) => async dispatch => {
  return api.categoria.create(dados).then(data => {
    return dispatch(createSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findById = id => async dispatch => {
  return api.categoria.findById(id).then(data => {
    return dispatch(findByIdSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const find = () => async dispatch => {
  return api.categoria.find().then(data => {
    return dispatch(findSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

export const findByIdAndUpdate = (id, data) => async dispatch => {
  return api.categoria.findByIdAndUpdate(id, data).then(data => {
    return dispatch(findByIdAndUpdateSuccess(data));
  }).catch(error => {
    dispatch(addError(errorHandler.getError(error)));
  })
}

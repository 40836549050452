import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "./assets/css/style.css";
import moment from "moment";
import "moment/locale/pt-br";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import decode from "jwt-decode";
import { composeWithDevTools } from "redux-devtools-extension";
import App from "./components/App";
import rootReducer from "./reducers/rootReducer";
import { userLoggedIn, userLoggedOut } from "./actions/auth";
import { getUsuario } from "./actions/usuario";
moment.locale("pt-BR");

const store =
  process.env.NODE_ENV === "development"
    ? createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
    : createStore(rootReducer, applyMiddleware(thunk));

if (localStorage[process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN]) {
  try {
    const payload = decode(
      localStorage[process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN]
    );
    const time = new Date().getTime() * 0.001;
    if (time < payload.exp) {
      const usuario = store.dispatch(getUsuario());
      usuario.then((usuario) => {
        if (payload.id === usuario._id) {
          const user = {
            token: localStorage[process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN],
            user: { ...usuario },
          };
          store.dispatch(userLoggedIn(user));
        } else {
          localStorage.removeItem(
            process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN
          );
          store.dispatch(userLoggedOut());
        }
        ReactDOM.render(
          <BrowserRouter>
            <Provider store={store}>
              <Route component={App} />
            </Provider>
          </BrowserRouter>,
          document.getElementById("root")
        );
      });
    } else {
      localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN);
      store.dispatch(userLoggedOut());
      localStorage.tokenExpired = "Sua sessão expirou";
      ReactDOM.render(
        <BrowserRouter>
          <Provider store={store}>
            <div>
              <Route component={App} />
            </div>
          </Provider>
        </BrowserRouter>,
        document.getElementById("root")
      );
    }
  } catch (err) {
    localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_AUTH_TOKEN);
    store.dispatch(userLoggedOut());
  }
} else {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <Route component={App} />
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );
  if (localStorage.tokenExpired) localStorage.removeItem("tokenExpired");
}

import api from "../api";
import errorHandler from "../utils/errorHandler";

const createSuccess = (status) => ({
  type: "CREATE_STATUS_SUCCESS",
  status,
});

const findSuccess = (dados) => ({
  type: "FIND_STATUS_SUCCESS",
  dados,
});

const findByIdSuccess = (status) => ({
  type: "FIND_BY_ID_STATUS_SUCCESS",
  status,
});

const findByIdAndUpdateSuccess = (status) => ({
  type: "UPDATE_STATUS_SUCCESS",
  status,
});

const addError = (errors) => ({
  type: "ADD_STATUS_ERROR",
  errors,
});

export const create = (dados) => async (dispatch) => {
  return api.status
    .create(dados)
    .then((data) => {
      return dispatch(createSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findById = (id) => async (dispatch) => {
  return api.status
    .findById(id)
    .then((data) => {
      return dispatch(findByIdSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const find = () => async (dispatch) => {
  return api.status
    .find()
    .then((data) => {
      return dispatch(findSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByContexto = (contexto) => async (dispatch) => {
  return api.status
    .findByContexto(contexto)
    .then((data) => {
      return dispatch(findSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

export const findByIdAndUpdate = (id, data) => async (dispatch) => {
  return api.status
    .findByIdAndUpdate(id, data)
    .then((data) => {
      return dispatch(findByIdAndUpdateSuccess(data));
    })
    .catch((error) => {
      dispatch(addError(errorHandler.getError(error)));
    });
};

const initState = {
  categoria: {
    nome: "",
    descricao: ""
  },
  categorias: [],
  quantidade: 0,
  errors: {}
}

const categoria = (state = initState, action = {}) => {
  switch (action.type) {
    case 'FIND_CATEGORIAS_SUCCESS':
      return {
        ...initState,
        categorias: action.dados.categorias,
        quantidade: action.dados.quantidade,
      };
    case 'CREATE_CATEGORIA_SUCCESS':
      return {
        ...initState,
        categorias: [action.categoria, ...state.categorias],
        quantidade: state.quantidade + 1
      };
    case 'FIND_BY_ID_CATEGORIA_SUCCESS':
      return {
        ...initState,
        categoria: { ...state.categoria, ...action.categoria }
      };
    case 'QUANTIDADE_CATEGORIAS_SUCCESS':
      return {
        ...state,
        quantidade: action.dados.quantidade
      };
    case 'UPDATE_CATEGORIA_SUCCESS':
      return {
        ...initState,
        quantidade: state.quantidade,
        categorias: state.categorias.map(categoria => {
          if (categoria._id === action.categoria._id)
            return action.categoria;
          return categoria;
        })
      };
    case 'ADD_CATEGORIA_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

export default categoria;
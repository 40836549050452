import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Icon,
  Form,
  Transition,
  Dropdown,
} from "semantic-ui-react";
import { create } from "../../../actions/jogador";
import { find as findCategorias } from "../../../actions/categoria";

import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class CadastrarJogador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jogador: { ...props.jogador },
      file: null,
      errors: {},
      sucesso: false,
      loading: false,
    };
  }

  componentDidMount = async () => {
    await this.props.findCategorias();
  };

  onChange = (e) => {
    this.setState({
      jogador: { ...this.state.jogador, [e.target.name]: e.target.value },
      errors: {},
    });
  };

  onChangeEndereco = (e) => {
    this.setState({
      jogador: {
        ...this.state.jogador,
        endereco: {
          ...this.state.jogador.endereco,
          [e.target.name]: e.target.value,
        },
      },
      errors: {},
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      jogador: { ...this.state.jogador, [name]: value },
      errors: {},
    });

  validate = (jogador) => {
    const errors = {};
    if (!jogador.nome) errors.nome = "Nome é obrigatório";
    return errors;
  };

  onSubmitCadastrar = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.jogador);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.create(this.state.jogador);
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  getCategorias = () => {
    return this.props.categorias.map((cat) => {
      return {
        key: cat._id,
        value: cat._id,
        text: cat.nome,
      };
    });
  };

  render() {
    const { jogador, errors, loading, sucesso } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Transition
            duration={{ show: 1200, hide: 0 }}
            animation="fade up"
            transitionOnMount={true}
            visible={sucesso}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <Icon name="check circle outline" size="massive" color="green" />
              <br />
              <h3>Jogador cadastrado com sucesso</h3>
              <Link to="/dashboard/jogadores" size="small">
                Voltar
              </Link>
            </div>
          </Transition>
          {!sucesso && (
            <Form
              onSubmit={this.onSubmitCadastrar}
              loading={loading}
              autoComplete={"off"}
            >
              <div className="ui-crud-form">
                <h3>Cadastro de Jogador</h3>
                <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                  <input
                    className="ui-crud-form-input"
                    name="nome"
                    id="nome"
                    type="text"
                    onChange={this.onChange}
                    value={jogador.nome}
                    autoFocus
                    required
                  />
                  <label htmlFor="nome" className="ui-crud-form-label">Nome</label>
                  <Erro text={errors.nome} />
                </Form.Field>
                <Form.Field error={!!errors.email} className="ui-crud-form-container">
                  <input
                    className="ui-crud-form-input"
                    name="email"
                    id="email"
                    type="text"
                    onChange={this.onChange}
                    value={jogador.email}
                    required
                  />
                  <label htmlFor="email" className="ui-crud-form-label">Email</label>
                  <Erro text={errors.email} />
                </Form.Field>
                <Form.Group widths={2}>
                  <Form.Field error={!!errors.cpf} className="ui-crud-form-container">
                    <input
                      id="cpf"
                      className="ui-crud-form-input"  
                      name="cpf"
                      type="text"
                      onChange={this.onChange}
                      value={jogador.cpf}
                      required
                    />
                    <label htmlFor="cpf" className="ui-crud-form-label">CPF</label>
                    <Erro text={errors.cpf} />
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      id="instagram"
                      className="ui-crud-form-input"
                      name="instagram"
                      type="text"
                      onChange={this.onChange}
                      value={jogador.instagram}
                      required
                    />
                    <label htmlFor="instagram" className="ui-crud-form-label">Instagram</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="twitter"
                      type="text"
                      onChange={this.onChange}
                      value={jogador.twitter}
                      required
                      id="twitter"
                    />
                    <label htmlFor="twitter" className="ui-crud-form-label">Twitter</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      required
                      name="pais"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.pais}
                      id="pais"
                    />
                    <label htmlFor="pais" className="ui-crud-form-label">Pais</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="estado"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.estado}
                      required
                      id="estado"
                    />
                    <label htmlFor="estado" className="ui-crud-form-label">Estado</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="cidade"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.cidade}
                      required
                      id="cidade"
                    />
                    <label htmlFor="cidade" className="ui-crud-form-label">Cidade</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="cep"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.cep}
                      required
                      id="cep"
                    />
                    <label htmlFor="cep" className="ui-crud-form-label">Cep</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      required
                      name="numero"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.numero}
                      id="numero"
                    />
                    <label htmlFor="numero" className="ui-crud-form-label">Número</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="bairro"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.bairro}
                      required
                      id="bairro"
                    />
                    <label htmlFor="bairro" className="ui-crud-form-label">Bairro</label>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="rua"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.rua}
                      required
                      id="rua"
                    />
                    <label htmlFor="rua" className="ui-crud-form-label">Rua</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      name="complemento"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.complemento}
                      required
                      id="complemento"
                    />
                    <label htmlFor="complemento" className="ui-crud-form-label">Complemento</label>
                  </Form.Field>
                  <Form.Field className="ui-crud-form-container">
                    <input
                      className="ui-crud-form-input"
                      required
                      name="telefone"
                      type="text"
                      onChange={this.onChangeEndereco}
                      value={jogador.endereco.telefone}
                      id="telefone"
                    />
                    <label htmlFor="telefone" className="ui-crud-form-label">Telefone</label>
                  </Form.Field>
                </Form.Group>
                <Form.Field error={!!errors.categorias}>
                  <label htmlFor="status">Categorias</label>
                  {!loading && (
                    <Dropdown
                      name="categorias"
                      placeholder="Categorias"
                      fluid
                      multiple
                      search
                      selection
                      options={this.getCategorias()}
                      noResultsMessage={"Nenhum categoria encontrada"}
                      onChange={this.onChangeSelect}
                      value={jogador.categorias}
                    />
                  )}
                  <Erro text={errors.categorias} />
                </Form.Field>
                <div style={{ textAlign: "center" }}>
                  <button
                    loading={loading}
                    disabled={loading}
                    className="ui-btn-1 ui-btn-theme-1"
                  >
                    Cadastrar Jogador
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categorias: state.categoria.categorias,
    jogador: state.jogador.jogador,
    jogadores: state.jogador.jogadores,
    quantidade: state.jogador.quantidade,
    errors: state.jogador.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create,
      findCategorias,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastrarJogador);

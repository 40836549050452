const initState = {
  campeonato: {
    nome: "",
    regulamento: "",
    dataInicio: "",
    dataFim: "",
    tipo: "",
    foto: "",
    categoria: {},
    vencedor: {},
    status: {},
  },
  campeonatos: [],
  campeonatosRelacionados: [],
  quantidade: 0,
  quantidadeBusca: 0,
  pagina: 1,
  errors: {},
};

const campeonato = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_CAMPEONATOS_SUCCESS":
      return {
        ...initState,
        pagina: action.pagina,
        campeonatos:
          action.pagina === 1
            ? action.dados.campeonatos
            : [...state.campeonatos, ...action.dados.campeonatos],
        quantidade: action.dados.quantidade,
        quantidadeBusca: action.dados.quantidade,
      };
    case "SEARCH_CAMPEONATOS_SUCCESS":
      return {
        ...state,
        campeonato: initState.campeonato,
        campeonatos: action.dados.campeonatos,
        quantidadeBusca: action.dados.quantidade,
      };
    case "FIND_REALACIONADOS_CATEGORIA_CAMPEONATO_SUCCESS":
      return {
        ...state,
        campeonatosRelacionados: action.campeonatosRelacionados,
      };
    case "CREATE_CAMPEONATO_SUCCESS":
      return {
        ...initState,
        campeonatos: [action.campeonato, ...state.campeonatos],
        quantidade: state.quantidade + 1,
      };
    case "FIND_BY_ID_CAMPEONATO_SUCCESS":
      return {
        ...initState,
        campeonato: { ...state.campeonato, ...action.campeonato },
      };
    case "FIND_BY_NOME_CAMPEONATO_SUCCESS":
      return {
        ...initState,
        campeonato: { ...state.campeonato, ...action.campeonato },
      };
    case "QUANTIDADE_CAMPEONATOS_SUCCESS":
      return {
        ...state,
        quantidade: action.dados.quantidade,
      };
    case "UPDATE_CAMPEONATO_SUCCESS":
      return {
        ...initState,
        quantidade: state.quantidade,
        quantidadeBusca: state.quantidadeBusca,
        campeonatos: state.campeonatos.map((campeonato) => {
          if (campeonato._id === action.campeonato._id)
            return action.campeonato;
          return campeonato;
        }),
        campeonato: action.campeonato,
      };
    case "UPDATE_IMAGE_CAMPEONATO_SUCCESS":
      return {
        ...initState,
        quantidade: state.quantidade,
        quantidadeBusca: state.quantidadeBusca,
        campeonatos: state.campeonatos.map((campeonato) => {
          if (campeonato._id === action.campeonato._id)
            return action.campeonato;
          return campeonato;
        }),
      };
    case "ADD_CAMPEONATO_ERROR":
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default campeonato;

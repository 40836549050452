import React from "react";
import PropTypes from "prop-types";
// import { scrollToPosition } from "../utils/links";
import { Switch, Route } from "react-router-dom";
import UserRoute from "./routes/UserRoute";
import GuestRoute from "./routes/GuestRoute";
//Geral
import Home from "./views/Home";
import Erro404 from "./views/errors/404";
import Login from "./views/auth/Login";

//Usuario
import Dashboard from "./controllers/Dashboard";
import Jogadores from "./views/jogador/Jogadores";
import Partidas from "./views/partida/Partidas";
import CadastrarPartida from "./views/partida/CadastrarPartida";
import EditarPartida from "./views/partida/EditarPartida";
// import PaginaPartida from "./views/partida/PaginaPartida";
import CadastrarJogador from "./views/jogador/CadastrarJogador";
import EditarJogador from "./views/jogador/EditarJogador";
import Categorias from "./views/categoria/Categorias";
import CadastrarCategoria from "./views/categoria/CadastrarCategoria";
import EditarCategoria from "./views/categoria/EditarCategoria";
import Logout from "./views/auth/Logout";
import Competidores from "./views/competidor/Competidores";
import CadastrarCompetidor from "./views/competidor/CadastrarCompetidor";
import EditarCompetidor from "./views/competidor/EditarCompetidor";
import Campeonatos from "./views/campeonato/Campeonatos";
import CadastrarCampeonato from "./views/campeonato/CadastrarCampeonato";
import EditarCampeonato from "./views/campeonato/EditarCampeonato";
import CadastrarStatus from "./views/status/CadastrarStatus";
import EditarStatus from "./views/status/EditarStatus";
import ListaStatus from "./views/status/ListaStatus";
import PaginaCampeonato from "./views/campeonato/PaginaCampeonato";

const App = () => {
  return (
    <div>
      {/* {scrollToPosition(0)} */}
      <Switch>
        {/* GENERAL */}
        <Route path="/" exact component={Home} />
        <Route path="/campeonato/:id" exact component={PaginaCampeonato} />
        <GuestRoute path="/login" exact component={Login} />
        <UserRoute path="/dashboard" exact component={Dashboard} />

        {/* USER */}
        <UserRoute path="/dashboard/partidas" exact component={Partidas} />
        <UserRoute
          path="/dashboard/partidas/cadastrar"
          exact
          component={CadastrarPartida}
        />
        <UserRoute
          path="/dashboard/partidas/:id"
          exact
          component={EditarPartida}
        />

        <UserRoute path="/dashboard/jogadores" exact component={Jogadores} />
        <UserRoute
          path="/dashboard/jogadores/cadastrar"
          exact
          component={CadastrarJogador}
        />
        <UserRoute
          path="/dashboard/jogadores/:id"
          exact
          component={EditarJogador}
        />

        <UserRoute path="/dashboard/categorias" exact component={Categorias} />
        <UserRoute
          path="/dashboard/categorias/cadastrar"
          exact
          component={CadastrarCategoria}
        />
        <UserRoute
          path="/dashboard/categorias/:id"
          exact
          component={EditarCategoria}
        />

        <UserRoute path="/dashboard/status" exact component={ListaStatus} />
        <UserRoute
          path="/dashboard/status/cadastrar"
          exact
          component={CadastrarStatus}
        />
        <UserRoute
          path="/dashboard/status/:id"
          exact
          component={EditarStatus}
        />

        <UserRoute
          path="/dashboard/competidores"
          exact
          component={Competidores}
        />
        <UserRoute
          path="/dashboard/competidores/cadastrar"
          exact
          component={CadastrarCompetidor}
        />
        <UserRoute
          path="/dashboard/competidores/:id"
          exact
          component={EditarCompetidor}
        />

        <UserRoute
          path="/dashboard/campeonatos"
          exact
          component={Campeonatos}
        />
        <UserRoute
          path="/dashboard/campeonatos/cadastrar"
          exact
          component={CadastrarCampeonato}
        />
        <UserRoute
          path="/dashboard/campeonatos/:id"
          exact
          component={EditarCampeonato}
        />

        <UserRoute path="/logout" exact component={Logout} />

        <Route component={Erro404} />
      </Switch>
    </div>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default App;

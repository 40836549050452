const initState = {
  status: {
    nome: "",
    contexto: "",
    cor: "",
  },
  listaStatus: [],
  quantidade: 0,
  errors: {},
};

const status = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_STATUS_SUCCESS":
      return {
        ...initState,
        listaStatus: action.dados.listaStatus,
        quantidade: action.dados.quantidade,
      };
    case "CREATE_STATUS_SUCCESS":
      return {
        ...initState,
        listaStatus: [action.status, ...state.listaStatus],
        quantidade: state.quantidade + 1,
      };
    case "FIND_BY_ID_STATUS_SUCCESS":
      return {
        ...initState,
        status: { ...state.status, ...action.status },
      };
    case "QUANTIDADE_STATUS_SUCCESS":
      return {
        ...state,
        quantidade: action.dados.quantidade,
      };
    case "UPDATE_STATUS_SUCCESS":
      return {
        ...initState,
        quantidade: state.quantidade,
        listaStatus: state.listaStatus.map((status) => {
          if (status._id === action.status._id) return action.status;
          return status;
        }),
      };
    case "ADD_STATUS_ERROR":
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default status;

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import {
  Transition,
  Card,
  Image,
  Button,
  Label,
  Icon,
} from "semantic-ui-react";
moment.locale("pt-BR");

const Campeonato = ({
  campeonato,
  className,
  isAuthenticated,
  readOnly,
  history,
}) => {
  const goTo = (campeonato) =>
    history.push(`/campeonato/${encodeURIComponent(campeonato)}`);
  return (
    <Transition duration={500} animation="fade up" transitionOnMount={true}>
      <Card
        className={readOnly && className ? className : "ui-card-3"}
        onClick={readOnly ? () => goTo(`${campeonato._id}`) : null}
        as="div"
      >
        <Image
          src={campeonato.foto}
          wrapped
          ui={false}
          className="ui-card-foto"
        />
        <Card.Content className="ui-card-1-content">
          <Card.Description>
            <p>{campeonato.nome}</p>
            <p>
              <Icon name="game" />
              &nbsp;&nbsp;
              {campeonato.categoria.nome}
            </p>

            <p>
              <Icon name="calendar alternate outline" />{" "}
              {moment(campeonato.dataInicio).format("DD/MM")}
              &nbsp; a &nbsp;
              {moment(campeonato.dataFim).format("DD/MM")}
              &nbsp; de &nbsp;
              {moment(campeonato.dataFim).format("YYYY")}
            </p>

            {!!(campeonato.vencedor || {})._id && (
              <p>
                <Icon name="trophy" color="yellow" />
                &nbsp;&nbsp;
                {campeonato.vencedor.nome}
              </p>
            )}

            {!readOnly && isAuthenticated ? (
              <span className="date">
                Criado {moment(campeonato.createdAt).fromNow()}
              </span>
            ) : null}
          </Card.Description>
          <Card.Description>{campeonato.descricao}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          {!readOnly && isAuthenticated ? (
            <Button
              as={Link}
              to={`/dashboard/campeonatos/${campeonato._id}`}
              className="ui-btn-1 ui-btn-theme-1"
              fluid
              size="small"
            >
              Editar
            </Button>
          ) : (
            <Label color={campeonato.status.cor} horizontal>
              {campeonato.status.nome}
            </Label>
          )}
        </Card.Content>
      </Card>
    </Transition>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
  };
}

export default connect(mapStateToProps, {})(Campeonato);

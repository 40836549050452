const initState = {
  competidor: {
    nome: "",
    jogadores: [],
  },
  competidores: [],
  quantidade: 0,
  errors: {},
};

const competidor = (state = initState, action = {}) => {
  switch (action.type) {
    case "FIND_COMPETIDORES_SUCCESS":
      return {
        ...initState,
        competidores: action.dados.competidores,
        quantidade: action.dados.quantidade,
      };
    case "CREATE_COMPETIDOR_SUCCESS":
      return {
        ...initState,
        competidores: [action.competidor, ...state.competidores],
        quantidade: state.quantidade + 1,
      };
    case "FIND_BY_ID_COMPETIDOR_SUCCESS":
      return {
        ...initState,
        competidor: { ...state.competidor, ...action.competidor },
      };
    case "QUANTIDADE_COMPETIDORES_SUCCESS":
      return {
        ...state,
        quantidade: action.dados.quantidade,
      };
    case "UPDATE_COMPETIDOR_SUCCESS":
      return {
        ...initState,
        quantidade: state.quantidade,
        competidores: state.competidores.map((competidor) => {
          if (competidor._id === action.competidor._id)
            return action.competidor;
          return competidor;
        }),
      };
    case "ADD_COMPETIDOR_ERROR":
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default competidor;

import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Form, Message, Icon, Transition } from 'semantic-ui-react';
import Erro from "../layouts/messages/Erro";
import * as FormValidator from "../../utils/formValidator";
import InputMask from 'react-input-mask';
import { enviarEmailContato } from "../../actions/email";

class FormContato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailContato: {
        ...props.email,
        mensagem: (this.props.mensagem) ? this.props.mensagem : ""
      },
      sucesso: false,
      loading: false,
      errors: {}
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.mensagem !== this.props.mensagem) {
      this.setState({
        emailContato: { ...this.state.emailContato, mensagem: this.props.mensagem }
      })
    }
  }

  onChange = e =>
    this.setState({
      emailContato: { ...this.state.emailContato, [e.target.name]: e.target.value },
      errors: {}
    });

  

  validate = emailContato => {
    const errors = {};
    if (FormValidator.isVazio(emailContato.nome))
      errors.nome = "Nome é obrigatório";
    if (!FormValidator.isEmail(emailContato.email))
      errors.email = "E-mail inválido";
    if (emailContato.telefone && !FormValidator.isNumeroCelularOuTelefone(emailContato.telefone))
      errors.telefone = "Número inválido";
    if (FormValidator.isVazio(emailContato.mensagem) || emailContato.mensagem.length < 10)
      errors.mensagem = "Mensagem muito curta, descreva um pouco mais";
    else if (emailContato.mensagem.length > 500)
      errors.mensagem = "Mensagem muito longa";

    return errors;
  };

  onSubmitSendEmail = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.emailContato);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.props.enviarEmailContato(this.state.emailContato);
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
        emailContato: {
          ...this.state.emailContato,
          nome: "",
          email: "",
          telefone: ""
        }
      });
    }
  };

  render() {
    const { emailContato, sucesso, loading, errors } = this.state;
    return (
      <div className="ui-form-contato">
        <Transition visible={sucesso} animation='fade down' duration={2000} transitionOnMount={true}>
          <Message positive floating style={{ textAlign: "center" }}><Icon name="check" />Email enviado com sucesso</Message>
        </Transition>

        <Transition visible={!!errors.global} animation='fade' duration={{ show: 800, hide: 0 }} transitionOnMount={true}>
          <Message negative floating style={{ textAlign: "center" }}><Icon name="clock outline" />{errors.global}</Message>
        </Transition>

        {!sucesso &&
          <Form onSubmit={this.onSubmitSendEmail} loading={loading} size="small" className="form-container-all">
            <Form.Field error={!!errors.nome} className="ui-form-contato-conatiner">
              <input
                className="ui-form-contato-input"
                type="text"
                id="name"
                name="nome"
                value={emailContato.nome}
                onChange={this.onChange}
                required
              />
              <label htmlFor="name" className="ui-form-contato-label">Nome</label>
              <Erro text={errors.nome} />
            </Form.Field>
            <Form.Group widths='equal'>
              <Form.Field error={!!errors.email} className="ui-form-contato-conatiner">
                <input
                  className="ui-form-contato-input"
                  type="text"
                  id="email"
                  name="email"
                  value={emailContato.email}
                  onChange={this.onChange}
                  required
                />
                <label htmlFor="email" className="ui-form-contato-label">Email</label>
                <Erro text={errors.email} />
              </Form.Field>
              <Form.Field error={!!errors.telefone} className="ui-form-contato-conatiner">
                <InputMask
                  className="ui-form-contato-input"
                  id="telefone"
                  name="telefone"
                  mask={'(99) 999999999'}
                  maskChar=""
                  alwaysShowMask={false}
                  value={emailContato.telefone}
                  onChange={this.onChange}
                  required
                />
                <label htmlFor="telefone" className="ui-form-contato-label">Telefone ou Celular</label>
                <Erro text={errors.telefone} />
              </Form.Field>
            </Form.Group>
            <Form.Field error={!!errors.mensagem} className="ui-form-contato-conatiner">
              <textarea
                className="ui-form-contato-input"
                name="mensagem"
                id="mensagem"
                rows={2}
                value={emailContato.mensagem}
                onChange={this.onChange}
                style={{ minHeight: 90 }}
                required
              />
              <label htmlFor="mensagem" className="ui-form-contato-label">Mensagem</label>
              <div className="label-contador">{emailContato.mensagem ? emailContato.mensagem.length : 0}/500</div>
              <Erro text={errors.mensagem} />
            </Form.Field>
            <button className="ui-btn-1 ui-btn-theme-1">Enviar</button>
          </Form>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.email.emailContato,
    errors: state.email.errors
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    enviarEmailContato
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContato);
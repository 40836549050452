import { combineReducers } from "redux";
import user from "./userReducer";
import partida from "./partidaReducer";
import categoria from "./categoriaReducer";
import jogador from "./jogadorReducer";
import email from "./emailReducer";
import competidor from "./competidorReducer";
import campeonato from "./campeonatoReducer";
import status from "./statusReducer";

const reducers = combineReducers({
  user,
  partida,
  categoria,
  jogador,
  email,
  competidor,
  campeonato,
  status,
});

export default reducers;

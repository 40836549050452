import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Icon,
  Form,
  Transition,
} from "semantic-ui-react";
import { create } from "../../../actions/categoria";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class CadastrarCategoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: { ...props.categoria },
      errors: {},
      sucesso: false,
      loading: false,
    };
  }

  onChange = (e) => {
    this.setState({
      categoria: { ...this.state.categoria, [e.target.name]: e.target.value },
      errors: {},
    });
  };

  validate = (categoria) => {
    const errors = {};
    if (FormValidator.isVazio(categoria.nome))
      errors.nome = "Nome é obrigatório";
    return errors;
  };

  onSubmitCadastrar = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.categoria);
    this.setState({ errors });
    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.create(this.state.categoria);
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  render() {
    const { categoria, errors, loading, sucesso } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Transition
            duration={{ show: 1200, hide: 0 }}
            animation="fade up"
            transitionOnMount={true}
            visible={sucesso}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <Icon name="check circle outline" size="massive" color="green" />
              <br />
              <br />
              <br />
              <h3>Categoria cadastrada com sucesso</h3>
              <Link to="/dashboard/categorias" size="small">
                Voltar
              </Link>
            </div>
          </Transition>
          {!sucesso && (
            <Form
              onSubmit={this.onSubmitCadastrar}
              loading={loading}
              autoComplete={"off"}
            >
              <div className="ui-crud-form">
                <h3>Cadastro de Categoria</h3>
                <Form.Field error={!!errors.nome} className="ui-crud-form-container">
                  <input
                    className="ui-crud-form-input"
                    name="nome"
                    type="text"
                    placeholder=""
                    onChange={this.onChange}
                    value={categoria.nome}
                    required
                    id="nome"
                  />
                  <label htmlFor="nome" className="ui-crud-form-label">Nome</label>
                  <Erro text={errors.nome} />
                </Form.Field>
                <div style={{ textAlign: "center" }}>
                  <button
                    loading={loading}
                    disabled={loading}
                    className="ui-btn-1 ui-btn-theme-1"
                  >
                    Cadastrar Categoria
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoria: state.categoria.categoria,
    categorias: state.categoria.categorias,
    quantidade: state.categoria.quantidade,
    errors: state.categoria.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastrarCategoria);

import React, { Component } from "react";
import MenuHorizontal from "../layouts/MenuHorizontal";
import { Grid, Container, Icon } from "semantic-ui-react";
import { scrollToIdOnClick } from "../../utils/links";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormContato from "../forms/FormContato";
import { find } from "../../actions/campeonato";
import Campeonato from "./campeonato/Campeonato";
import { Rodape } from "../layouts/Rodape";
// import parceiros from "../../assets/content/parceiros.json";

const handleScroll = (e, id) => {
  e.preventDefault();
  scrollToIdOnClick(id);
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = async () => {
    await this.props.find(1, "");
    this.setState({ loading: false });
    if (this.props.location.hash) {
      scrollToIdOnClick(this.props.location.hash);
      this.props.history.push("/");
    }
  };

  render() {
    const { campeonatos } = this.props;
    return (
      <div>
        <MenuHorizontal home={true} />
        <div className="corpo-home" id="home">
          <div className="corpo-home-fade"></div>
          <Container>
            <Grid verticalAlign="middle" stackable className="">
              <Grid.Column>
                <h1>
                  Plataforma de gerenciamento de campeonatos online e
                  presenciais de eSports&nbsp;
                </h1>
              </Grid.Column>
            </Grid>{" "}
            <Grid
              verticalAlign="middle"
              stackable
              className="ui-home-section-1"
            >
              <Grid.Column style={{ position: "relative" }}>
                <a
                  href="#campeonatos"
                  onClick={(e) => handleScroll(e, "#campeonatos")}
                >
                  <Icon name="chevron down" />
                </a>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <div className="ui-home-section-2" id="campeonatos">
          <Container>
            <h2 style={{ marginBottom: "30px" }}>Campeonatos</h2>
            <Grid stackable columns={4} doubling>
              {!!campeonatos.length &&
                campeonatos.map((campeonato) => (
                  <Grid.Column key={campeonato._id}>
                    <Campeonato
                      campeonato={campeonato}
                      className="ui-card-1"
                      readOnly={true}
                      {...this.props}
                    />
                  </Grid.Column>
                ))}
            </Grid>
          </Container>
        </div>
        <div className="ui-home-section-3" id="sobre">
          <Container>
            <div className="titulo-imagem-jogador">
              <h2>Aqui você encontra os verdadeiros campeões</h2>   
            </div>
            <Grid stackable columns={3} doubling centered>
              <Grid.Column>
                <div className="ui-card-2">
                  {/* <img src="/assets/imagens/campeonatos/rocket.svg" alt="Sobre nós" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 10 4.477 10 10z" />
                  </svg>
                  <p>Tempo real</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M10.119 16.064c2.293-.53 4.427-.994 3.394-2.946-3.147-5.941-.835-9.118 2.488-9.118 3.388 0 5.643 3.299 2.488 9.119-1.065 1.964 1.149 2.427 3.393 2.946 1.985.458 2.118 1.428 2.118 3.107l-.003.828h-1.329c0-2.089.083-2.367-1.226-2.669-1.901-.438-3.695-.852-4.351-2.304-.239-.53-.395-1.402.226-2.543 1.372-2.532 1.719-4.726.949-6.017-.902-1.517-3.617-1.509-4.512-.022-.768 1.273-.426 3.479.936 6.05.607 1.146.447 2.016.206 2.543-.66 1.445-2.472 1.863-4.39 2.305-1.252.29-1.172.588-1.172 2.657h-1.331c0-2.196-.176-3.406 2.116-3.936zm-10.117 3.936h1.329c0-1.918-.186-1.385 1.824-1.973 1.014-.295 1.91-.723 2.316-1.612.212-.463.355-1.22-.162-2.197-.952-1.798-1.219-3.374-.712-4.215.547-.909 2.27-.908 2.819.015.935 1.567-.793 3.982-1.02 4.982h1.396c.44-1 1.206-2.208 1.206-3.9 0-2.01-1.312-3.1-2.998-3.1-2.493 0-4.227 2.383-1.866 6.839.774 1.464-.826 1.812-2.545 2.209-1.49.345-1.589 1.072-1.589 2.334l.002.618z" />
                  </svg>
                  <p>Partidas</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M13.408 18c.498-3.947 5.592-7.197 5.592-17h-14c0 9.803 5.105 13.053 5.604 17h2.804zm-3.614-11.472l1.46-.202.643-1.326.643 1.326 1.46.202-1.063 1.021.26 1.451-1.3-.695-1.3.695.26-1.451-1.063-1.021zm-3.803 4.128c.286.638.585 1.231.882 1.783-4.065-1.348-6.501-5.334-6.873-9.439h4.077c.036.482.08.955.139 1.405h-2.689c.427 2.001 1.549 4.729 4.464 6.251zm10.009 10.963v1.381h-8v-1.381c1.941 0 2.369-1.433 2.571-2.619h2.866c.193 1.187.565 2.619 2.563 2.619zm8-18.619c-.372 4.105-2.808 8.091-6.873 9.438.297-.552.596-1.145.882-1.783 2.915-1.521 4.037-4.25 4.464-6.251h-2.688c.059-.45.103-.922.139-1.405h4.076z" />
                  </svg>
                  <p>Campeonatos</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M14.969 7.547l.031.191c0 .193-.096.379-.264.496-.538.372-.467.278-.67.885-.084.253-.33.424-.605.424h-.002c-.664-.002-.549-.038-1.083.338-.112.08-.244.119-.376.119s-.264-.039-.376-.118c-.534-.376-.419-.34-1.083-.338h-.002c-.275 0-.521-.171-.605-.424-.204-.607-.133-.514-.669-.886-.169-.117-.265-.303-.265-.496l.031-.19c.207-.604.208-.488 0-1.094l-.031-.191c0-.193.096-.379.265-.497.536-.372.465-.277.669-.885.084-.253.33-.424.605-.424h.002c.662.002.544.041 1.083-.338.112-.08.244-.119.376-.119s.264.039.376.118c.534.376.419.34 1.083.338h.002c.275 0 .521.171.605.424.203.607.132.513.67.885.168.118.264.304.264.497l-.031.191c-.207.604-.208.488 0 1.094zm-1.469-1.198l-.465-.464-1.41 1.446-.66-.627-.465.464 1.125 1.091 1.875-1.91zm2.5 4.651h-8v1h8v-1zm-1 2h-6v1h6v-1zm1 2h-8v1h8v-1zm-7.771 4c-.77 3.458-3.229 3.5-3.229 1.375v-15.333c0-1.333-.062-2.386-1-3.042h13c1.354 0 2 .625 2 2v13h2v-13c0-2.021-.844-4-4-4h-12c-3.494 0-5 2.906-5 5h3v16c0 1.657 1.343 3 3 3h14c2.688-.531 3.875-2.938 4-5h-15.771z" />
                  </svg>
                  <p>Confiabilidade</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M24 15.951c0-2.468-1.744-4.451-4.114-5.353.069-.395.114-.793.114-1.195 0-4.88-4.751-8.403-10-8.403-5.282 0-10 3.549-10 8.403 0 1.721.616 3.411 1.706 4.724.045 1.536-.852 3.741-1.661 5.346 2.168-.394 5.251-1.265 6.648-2.13 1.18.289 2.313.424 3.383.424l.273-.013c.866 2.251 3.314 4.052 6.6 4.052.748 0 1.54-.094 2.366-.297.978.604 3.137 1.215 4.654 1.491-.566-1.124-1.194-2.667-1.162-3.742.763-.919 1.193-2.101 1.193-3.307zm-3.148 4.084c-.396-.168-.421-.2-1.201-.682-1.011.247-1.683.438-2.702.438-2.179 0-3.76-1.016-4.492-2.265 3.247-.654 5.686-2.607 6.827-5.01 1.61.644 2.716 1.941 2.716 3.435 0 .542-.015 1.158-1.171 2.548-.023.763-.043 1.005.023 1.536zm-14.496-4.849c-.805.5-1.169.794-3.004 1.384.436-1.599.356-2.382.332-3.201-.318-.384-1.684-1.71-1.684-3.966 0-3.522 3.589-6.388 8-6.388 4.412 0 8 2.866 8 6.388 0 3.053-3.029 6.348-7.924 6.348-1.649 0-2.904-.364-3.72-.565zm6.643-3.537c-.212.071-.441-.044-.511-.256 0 0-1.496-.501-3.272.088l1.166 1.814c.121.179.037.422-.164.49l-.794.263-.167.028c-.149 0-.294-.064-.396-.179l-1.604-1.77c-.873.276-1.862-.042-2.192-.865-.076-.188-.114-.389-.114-.592 0-.585.327-1.183 1.038-1.533 3.559-1.747 4.128-3.696 4.128-3.696-.086-.262.11-.532.382-.532.169 0 .327.108.383.277l2.371 5.952c.071.212-.044.441-.254.511zm1.687-2.043l-.614-.261c.267-.634.293-1.371.014-2.058-.279-.688-.808-1.199-1.441-1.466l.26-.615c1.265.535 2.046 1.771 2.046 3.091 0 .454-.092.898-.265 1.309zm-1.063-.451l-.599-.254c.154-.365.169-.789.008-1.185-.16-.396-.466-.69-.83-.843l.253-.601c.518.219.952.635 1.179 1.198.229.564.207 1.165-.011 1.685z" />
                  </svg>
                  <p>Anúncios</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M14 11c-1.656 0-3 1.344-3 3s1.344 3 3 3 3-1.344 3-3-1.344-3-3-3zm.15 4.484v.315h-.3v-.299c-.311-.005-.632-.079-.899-.217l.135-.493c.287.11.669.229.968.162.345-.078.415-.433.034-.604-.279-.129-1.133-.242-1.133-.973 0-.409.313-.775.895-.855v-.319h.301v.305c.217.006.461.043.732.126l-.108.493c-.23-.08-.485-.154-.733-.139-.446.026-.486.413-.174.575.514.242 1.182.42 1.182 1.063.001.516-.403.791-.9.86zm-10.15-7.484v12h20v-12h-20zm18 10h-16v-8h16v8zm-1-12h-19v11h-2v-13h21v2z" />
                  </svg>
                  <p>Ganhe prêmios</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M3.848 19h-.848c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-3c0-7.175 5.377-13 12-13s12 5.825 12 13v3c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-.848c-2.69 4.633-6.904 5-8.152 5-1.248 0-5.462-.367-8.152-5zm16.152-5.876c-.601.236-1.269-.18-1.269-.797 0-.304-.022-.61-.053-.915-1.761-.254-3.618-1.926-3.699-3.723-1.315 2.005-4.525 4.17-7.044 4.17 1.086-.699 1.839-2.773 1.903-3.508-.581 1.092-2.898 3.136-4.551 3.487l-.018.489c0 .619-.669 1.032-1.269.797v3.771c.287.256.632.464 1.041.594.225.072.412.224.521.424 2.206 4.046 5.426 4.087 6.438 4.087.929 0 3.719-.035 5.877-3.169-1.071.433-2.265.604-3.759.653-.37.6-1.18 1.016-2.118 1.016-1.288 0-2.333-.784-2.333-1.75s1.045-1.75 2.333-1.75c.933 0 1.738.411 2.112 1.005 1.9-.026 4.336-.334 5.888-2.645v-2.236zm-11-.624c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm6 0c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm5.478-1.5h1.357c-.856-5.118-4.937-9-9.835-9-4.898 0-8.979 3.882-9.835 9h1.357c.52-4.023 3.411-7.722 8.478-7.722s7.958 3.699 8.478 7.722z" />
                  </svg>
                  <p>Suporte integral</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M21.169 19.754c.522-.79.831-1.735.831-2.754 0-2.761-2.238-5-5-5s-5 2.239-5 5 2.238 5 5 5c1.019 0 1.964-.309 2.755-.832l2.831 2.832 1.414-1.414-2.831-2.832zm-4.169.246c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm-4.89 2h-7.11l2.599-3h2.696c.345 1.152.976 2.18 1.815 3zm-2.11-5h-10v-17h22v12.11c-.574-.586-1.251-1.068-2-1.425v-8.685h-18v13h8.295c-.19.634-.295 1.305-.295 2zm-4-4h-2v-6h2v6zm3 0h-2v-9h2v9zm3 0h-2v-4h2v4z" />
                  </svg>
                  <p>Controle de acessos</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-card-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M6 2l1.171.203c-.355 2.245.791 2.519 2.699 2.874 1.468.273 3.13.622 3.13 3.284v.639h-1.183v-.639c0-1.556-.48-1.809-2.164-2.122-2.583-.48-4.096-1.391-3.653-4.239zm18 14c0 3.312-2.607 6-5.825 6-1.511 0-2.886-.595-3.921-1.565-1.311-1.229-3.278-1.132-4.55.038-1.03.948-2.389 1.527-3.879 1.527-3.217 0-5.825-2.688-5.825-6s2.608-6 5.825-6l12.563.007c3.118.116 5.612 2.755 5.612 5.993zm-15-1h-2v-2h-2v2h-2v2h2v2h2v-2h2v-2zm4 1h-2v1h2v-1zm4-2c0 .552.447 1 1 1s1-.448 1-1-.447-1-1-1-1 .448-1 1zm0 2c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm2 2c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm2-2c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1z" />
                  </svg>
                  <p>Franquias oficiais</p>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>

        <div className="ui-home-section-4" id="contato">
          <Container>
            <h2>Contato</h2>
            <Grid
              stackable
              columns={2}
              centered
              divided
              verticalAlign="middle"
              style={{ marginTop: "40px" }}
            >
              <Grid.Column>
                <div className="ui-div-section-4">
                  <a
                    href="https://linktr.ee/connectgamesfestival"
                    rel="noopener noreferrer"
                    className="ui-social-whatsapp"
                    target="_blank"
                  >
                    <Icon name="share alternate" />
                  </a>
                  <h3>
                    Chama a gente nas redes sociais!{" "}
                    <span role="img" aria-label="Emoji sorridente">
                      😎
                    </span>
                  </h3>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="ui-div-section-4">
                  <h3>Ou se preferir, envie-nos um email</h3>
                  <br />
                  <FormContato />
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>

        <div className="ui-rodape">
          <p>
            <b>Connect Games Festival</b>
          </p>
          <p>Juiz de Fora - MG</p>
          {/* <p>
            <Icon name="discord" /> Link &nbsp;•&nbsp; <Icon name="whatsapp" />
            Number
          </p> */}
          <br />
          <p>
            <a
              href="https://www.facebook.com/Connect-Games-Festival-100227815146398"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="facebook" size="big" />
            </a>
            &nbsp;
            <a
              href="https://www.instagram.com/connectgamesf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="instagram" size="big" />
            </a>
            &nbsp;
            <a
              href="https://www.linkedin.com/company/jf-festival/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" size="big" />
            </a>
            <a
              href="https://twitter.com/connectgamesf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="twitter" size="big" />
            </a>
          </p>
          <Rodape />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campeonatos: state.campeonato.campeonatos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ find }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import MenuHorizontal from "../../layouts/MenuHorizontal";
import {
  Container,
  Input,
  Icon,
  Button,
  Form,
  Transition,
  Checkbox,
} from "semantic-ui-react";
import { find, search, create } from "../../../actions/partida";
import { find as findCompetidores } from "../../../actions/competidor";
import { find as findCampeonatos } from "../../../actions/campeonato";
import { findByContexto as findStatusByContexto } from "../../../actions/status";
import * as FormValidator from "../../../utils/formValidator";
import { Rodape } from "../../layouts/Rodape";
import Erro from "../../layouts/messages/Erro";

class ManterPartida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partida: { ...props.partida },
      file: null,
      errors: {},
      sucesso: false,
      loading: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.stringBusca)
      this.setState({ stringBusca: this.props.match.params.stringBusca });
    await this.props.find(
      this.props.pagina,
      this.props.match.params.stringBusca
    );
    await this.props.findCompetidores();
    await this.props.findCampeonatos(1);
    await this.props.findStatusByContexto("Partida");

    this.setState({ loading: false });
  };

  onChange = (e) => {
    this.setState({
      partida: { ...this.state.partida, [e.target.name]: e.target.value },
      errors: {},
    });
  };

  onChangeBoolean = (e, { name, checked }) => {
    e.preventDefault();
    this.setState({
      partida: { ...this.state.partida, [name]: checked },
      errors: {},
      sucesso: false,
    });
  };

  onChangeSelect = (e, { name, value }) =>
    this.setState({
      partida: { ...this.state.partida, [name]: value },
      errors: {},
    });

  onChangeObjectSelect = (e, { name, value }) =>
    this.setState({
      partida: {
        ...this.state.partida,
        [name]: {
          ...[name],
          _id: value,
        },
      },
      errors: {},
      sucesso: false,
    });

  onChangeFile = (e) => {
    const mimeImg = ["image/jpeg", "image/png"];

    if (
      e.target.files &&
      e.target.files[0] &&
      mimeImg.includes(e.target.files[0].type)
    ) {
      this.setState({
        file: e.target.files[0],
        errors: {},
        partida: {
          ...this.state.partida,
          foto: URL.createObjectURL(e.target.files[0]),
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          foto: "Formato de arquivo inválido. Selecione uma imagem png ou jpeg",
        },
      });
    }
  };

  validate = (partida) => {
    const errors = {};
    if (!FormValidator.isSelected(partida.campeonato))
      errors.campeonato = "Campeonato é obrigatório";

    if (partida.patrocinado) {
      if (!FormValidator.isUrl(partida.link))
        errors.link = "Formato de URL inválido. (Exemplo válido: www.site.com)";
    } else {
      if (FormValidator.isVazio(partida.competidor1))
        errors.competidor = "Competidor 1 é obrigatório";
      if (FormValidator.isVazio(partida.competidor2))
        errors.competidor = "Competidor 2 é obrigatório";
      if (!FormValidator.isDataValida(partida.data))
        errors.data = "Data Inválida";
      if (FormValidator.isVazio(partida.horario))
        errors.horario = "Horário Inválido";
      if (!FormValidator.isSelected(partida.status))
        errors.status = "Status é obrigatório";
    }
    return errors;
  };

  fileUpload(file) {
    const formData = new FormData();
    formData.append("imagem", file);
    formData.append("props", JSON.stringify(this.state.partida));
    return formData;
  }

  onSubmitCadastrar = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.partida);
    this.setState({ errors });
    if (FormValidator.objIsEmpty(errors)) {
      this.setState({ loading: true });
      await this.props.create(this.fileUpload(this.state.file));
      this.setState({
        loading: false,
        sucesso: FormValidator.objIsEmpty(this.props.errors),
        errors: this.props.errors,
      });
    }
  };

  getCampeonatos = () => {
    return this.props.campeonatos.map((campeonato) => {
      return {
        key: campeonato._id,
        value: campeonato._id,
        text: campeonato.nome,
      };
    });
  };

  getCompetidores = () => {
    return this.props.competidores.map((competidor) => {
      return {
        key: competidor._id,
        value: competidor._id,
        text: competidor.nome,
      };
    });
  };

  getStatus = () => {
    return this.props.listaStatus.map((status) => {
      return {
        key: status._id,
        value: status._id,
        text: status.nome,
      };
    });
  };

  render() {
    const { partida, errors, loading, sucesso } = this.state;
    return (
      <div>
        <MenuHorizontal />
        <Container style={{ paddingTop: "10px" }}>
          <Transition
            duration={{ show: 1200, hide: 0 }}
            animation="fade up"
            transitionOnMount={true}
            visible={sucesso}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <Icon name="check circle outline" size="massive" color="green" />
              <br />
              <h3>Partida criada com sucesso</h3>
              <br />
              <Button
                as={Link}
                to={`/partidas/${encodeURIComponent(partida.id)}`}
                className="ui-btn-1"
              >
                Acessar Página da Partida
              </Button>
              <br />
              <br />
              <br />
              <Link to="/dashboard/partidas" size="small">
                Voltar
              </Link>
            </div>
          </Transition>
          {!sucesso && (
            <Form
              onSubmit={this.onSubmitCadastrar}
              loading={loading}
              autoComplete={"off"}
            >
              <div className="ui-crud-form">
                <h3>Cadastro de Partida</h3>
                <div style={{ textAlign: "center" }}>
                  <Button
                    className="ui-preview-foto"
                    style={{
                      backgroundImage: `url(${partida.foto})`,
                      backgroundSize: "cover",
                      opacity: loading ? 0.3 : 1,
                    }}
                    as="label"
                    htmlFor="imagem"
                  >
                    {!this.state.file && (
                      <span>
                        Selecionar Imagem
                        <br />
                        <br />
                        900 x 500
                      </span>
                    )}
                    <Input
                      type="file"
                      id="imagem"
                      name="imagem"
                      style={{ display: "none" }}
                      onChange={this.onChangeFile}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                  </Button>
                  <Erro text={errors.foto} />
                  <br />
                </div>
                <Form.Field error={!!errors.patrocinado}>
                  <label htmlFor="patrocinado">Patrocinado</label>
                  <Checkbox
                    name="patrocinado"
                    onChange={this.onChangeBoolean}
                    toggle
                    checked={partida.patrocinado}
                    disabled={!!loading}
                  />
                  <Erro text={errors.patrocinado} />
                </Form.Field>

                <Form.Field error={!!errors.campeonato}>
                  <label htmlFor="campeonato">Campeonato</label>
                  <Form.Select
                    fluid
                    name="campeonato"
                    options={this.getCampeonatos()}
                    onChange={this.onChangeObjectSelect}
                    value={partida.campeonato._id}
                    placeholder="Campeonato"
                  />
                  <Erro text={errors.campeonato} />
                </Form.Field>

                {!partida.patrocinado && (
                  <Form.Group widths={2}>
                    <Form.Field error={!!errors.competidor1}>
                      <label htmlFor="nome">Competidor 1</label>
                      <Form.Select
                        fluid
                        name="competidor1"
                        options={this.getCompetidores()}
                        onChange={this.onChangeObjectSelect}
                        value={partida.competidor1._id}
                        placeholder="Competidor 1"
                      />
                      <Erro text={errors.competidor1} />
                    </Form.Field>
                    <Form.Field error={!!errors.competidor2}>
                      <label htmlFor="nome">Competidor 2</label>
                      <Form.Select
                        fluid
                        name="competidor2"
                        options={this.getCompetidores()}
                        onChange={this.onChangeObjectSelect}
                        value={partida.competidor2._id}
                        placeholder="Competidor 2"
                      />
                      <Erro text={errors.competidor2} />
                    </Form.Field>
                  </Form.Group>
                )}

                {partida.patrocinado && (
                  <Form.Field error={!!errors.link}>
                    <label htmlFor="link">Link do anúncio</label>
                    <Input
                      name="link"
                      type="text"
                      placeholder="www.site.com/anuncio"
                      onChange={this.onChange}
                      value={partida.link}
                      autoFocus
                    />
                    <Erro text={errors.link} />
                  </Form.Field>
                )}

                {!partida.patrocinado && (
                  <Form.Group widths={2}>
                    <Form.Field error={!!errors.data}>
                      <label htmlFor="data">Data</label>
                      <input
                        className="ui-crud-form-input"
                        name="data"
                        type="date"
                        min="1"
                        step="1"
                        onChange={this.onChange}
                        value={partida.data}
                        required
                      />
                      <Erro text={errors.data} />
                    </Form.Field>

                    <Form.Field error={!!errors.horario}>
                      <label htmlFor="horario">Horário</label>
                      <input
                        className="ui-crud-form-input"
                        name="horario"
                        type="time"
                        min="1"
                        step="1"
                        onChange={this.onChange}
                        value={partida.horario}
                        required
                      />
                      <Erro text={errors.horario} />
                    </Form.Field>
                  </Form.Group>
                )}

                {!partida.patrocinado && (
                  <Form.Field error={!!errors.status}>
                    <label htmlFor="status">Status</label>
                    <Form.Select
                      fluid
                      name="status"
                      options={this.getStatus()}
                      onChange={this.onChangeSelect}
                      value={partida.status._id}
                      placeholder="Status"
                    />
                    <Erro text={errors.status} />
                  </Form.Field>
                )}
                <Form.Field error={!!errors.descricao} className="ui-crud-form-container" style={{marginTop: "30px"}}>
                  <textarea
                    className="ui-crud-form-input"
                    name="descricao"
                    id="descricao"
                    placeholder=""
                    rows={2}
                    value={partida.descricao}
                    onChange={this.onChange}
                    style={{ minHeight: 120 }}
                    required
                  /> 
                  {partida.patrocinado ? (
                    <label htmlFor="descricao" className="ui-crud-form-label">Descrição do anúncio</label>
                  ) : (
                    <label htmlFor="descricao" className="ui-crud-form-label">Detalhes da partida</label>
                  )}
                  <Erro text={errors.descricao} />
                </Form.Field>
                <div style={{ textAlign: "center" }}>
                  <button
                    loading={loading}
                    disabled={loading}
                    className="ui-btn-1 ui-btn-theme-1"
                  >
                    Criar Partida
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Container>
        <Rodape />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    competidores: state.competidor.competidores,
    listaStatus: state.status.listaStatus,
    campeonatos: state.campeonato.campeonatos,
    partida: state.partida.partida,
    partidas: state.partida.partidas ? state.partida.partidas : [],
    quantidadeBusca: state.partida.quantidadeBusca,
    quantidade: state.partida.quantidade,
    pagina: state.partida.pagina,
    errors: state.partida.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      find,
      search,
      create,
      findCompetidores,
      findStatusByContexto,
      findCampeonatos,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManterPartida);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import { bindActionCreators } from "redux";
import { Menu, Icon, Responsive, Transition, Divider } from "semantic-ui-react";
import { scrollToIdOnClick } from "../../utils/links";

class MenuHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sigla: "",
      activeItem: "",
      usuario: {},
      visible: false,
      drawerWidth: "100%",
      loading: true,
    };
  }

  handleScroll = (e) => {
    this.closeDrawer();
    scrollToIdOnClick(e);
  };

  closeDrawer = () => {
    if (this.state.visible) this.setState({ visible: false });
  };

  drawer = ({ tamanho }) =>
    this.setState({
      visible: !this.state.visible,
      drawerWidth: tamanho ? tamanho : "100%",
    });

  logout = () => {
    this.closeDrawer();
    this.props.logout();
  };

  render() {
    // const { usuario } = this.props;
    const { visible } = this.state;
    if (this.props.isAuthenticated) {
      return (
        <div style={styles.normalize}>
          <div>
            <div className="menu-horizontal">
              <Menu fixed="top" secondary>
                <Menu.Item position="left" className="logo-dashboard">
                  <Link to="/">
                    <img
                      href="#home"
                      src="/assets/imagens/logo1.png"
                      alt="Connect Games Logo"
                    />
                  </Link>
                </Menu.Item>
                <Responsive
                  as={Menu.Menu}
                  minWidth={880}
                  position="right"
                  style={styles.menumenu}
                >
                  <Menu.Menu position="right">
                    <Menu.Item>
                      <Link to="/dashboard" className="menu-link menu-item">
                        <Icon name="dashboard" /> Dashboard
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <span
                        onClick={this.props.logout}
                        className="menu-link menu-item"
                      >
                        <Icon name="sign out" /> Sair
                      </span>
                    </Menu.Item>
                  </Menu.Menu>
                </Responsive>
                <Responsive
                  as={Menu.Menu}
                  maxWidth={879}
                  position="right"
                  style={styles.menumenu}
                >
                  <Menu.Item>
                    <span
                      className="menu-item-drawer menu-link"
                      onClick={this.drawer}
                    >
                      <Icon name={!visible ? "bars" : "delete"} size="large" />
                    </span>
                  </Menu.Item>
                </Responsive>
              </Menu>
            </div>
            {/* Drawer */}
            <Transition
              duration={{ show: 600, hide: 300 }}
              animation="fade down"
              size="huge"
              visible={visible}
            >
              <div style={styles.drawer} className="text-center">
                <div>
                  <Link to="/perfil" className="menu-link menu-link-drawer">
                    <Icon name="user circle outline" /> Perfil
                  </Link>
                  <Link
                    to="/dashboard/partidas"
                    className="menu-link menu-link-drawer"
                  >
                    <Icon name="graduation cap" /> Partidas
                  </Link>
                  <Link
                    to="/dashboard/jogadores"
                    className="menu-link menu-link-drawer"
                  >
                    <Icon name="group" /> Jogadores
                  </Link>
                  <Link
                    to="/dashboard/categorias"
                    className="menu-link menu-link-drawer"
                  >
                    <Icon name="tags" /> Categorias
                  </Link>
                  <Link
                    to="/dashboard/status"
                    className="menu-link menu-link-drawer"
                  >
                    <Icon name="tags" /> Status
                  </Link>
                  <Link
                    to="/dashboard/competidores"
                    className="menu-link menu-link-drawer"
                  >
                    <Icon name="user" /> Competidores
                  </Link>
                  <Divider />
                  <span
                    onClick={this.logout}
                    className="menu-link menu-link-drawer"
                  >
                    <Icon name="sign out" /> Sair
                  </span>
                </div>
              </div>
            </Transition>
          </div>

          <Transition
            duration={{ show: 300, hide: 200 }}
            animation="fade"
            size="huge"
            visible={visible}
          >
            <div
              style={styles.bgDrawer}
              onClick={() => this.drawer({ tamanho: "300px" })}
            ></div>
          </Transition>
        </div>
      );
    } else {
      return (
        <div style={styles.normalize}>
          {this.props.home ? (
            <div>
              <div className="menu-horizontal">
                <Menu fixed="top" secondary>
                  <Menu.Item position="left" className="logo-home">
                    <a href="#home" onClick={this.handleScroll}>
                      <img
                        href="#home"
                        src="/assets/imagens/logo1.png"
                        alt="Connect Games Festival"
                      />
                    </a>
                  </Menu.Item>
                  <Responsive
                    as={Menu.Menu}
                    minWidth={880}
                    position="right"
                    style={styles.menumenu}
                  >
                    <Menu.Item name="campeonatos">
                      <a
                        href="#campeonatos"
                        className="menu-link menu-item"
                        onClick={this.handleScroll}
                      >
                        Campeonatos
                      </a>
                    </Menu.Item>
                    <Menu.Item name="vantagens">
                      <a
                        href="#sobre"
                        className="menu-link menu-item"
                        onClick={this.handleScroll}
                      >
                        Nossa plataforma
                      </a>
                    </Menu.Item>
                    {/* <Menu.Item name="parceiros">
                      <a
                        href="#parceiros"
                        className="menu-link menu-item"
                        onClick={this.handleScroll}
                      >
                        Parceiros
                      </a>
                    </Menu.Item> */}
                    <Menu.Item name="contato">
                      <a
                        href="#contato"
                        className="menu-link menu-item contato"
                        onClick={this.handleScroll}
                      >
                        Entre em Contato
                      </a>
                    </Menu.Item>
                  </Responsive>
                  <Responsive
                    as={Menu.Menu}
                    maxWidth={879}
                    position="right"
                    style={styles.menumenu}
                  >
                    <Menu.Item>
                      <span
                        className="menu-item-drawer menu-link"
                        onClick={this.drawer}
                      >
                        <Icon
                          name={!visible ? "bars" : "delete"}
                          size="large"
                        />
                      </span>
                    </Menu.Item>
                  </Responsive>
                </Menu>
              </div>
              {/* Drawer */}
              <Transition
                duration={{ show: 600, hide: 300 }}
                animation="fade down"
                size="huge"
                visible={visible}
              >
                <div style={styles.drawer} className="ui-drawer">
                  <div>
                    <a
                      href="#partidas"
                      className="menu-link menu-link-drawer"
                      onClick={this.handleScroll}
                    >
                      Partidas
                    </a>
                  </div>
                  <div>
                    <a
                      href="#sobre"
                      className="menu-link menu-link-drawer"
                      onClick={this.handleScroll}
                    >
                      Funcionalidades
                    </a>
                  </div>
                  <div>
                    <a
                      href="#contato"
                      className="menu-link menu-link-drawer"
                      onClick={this.handleScroll}
                    >
                      Entre em Contato
                    </a>
                  </div>
                  {/* <div style={{ margin: "15px 20px 20px 20px" }}>
                    <Button as={Link} to="/login" className="ui-btn-1 ui-btn-theme-1" fluid>
                      Entrar
                    </Button>
                  </div> */}
                </div>
              </Transition>
            </div>
          ) : (
            <div>
              <div className="menu-horizontal">
                <Menu fixed="top" secondary>
                  <Menu.Item position="left" className="logo-home">
                    <Link to="/">
                      <img src="/assets/imagens/logo1.png" alt="Partidas" />
                    </Link>
                  </Menu.Item>
                  <Responsive
                    as={Menu.Menu}
                    minWidth={880}
                    position="right"
                    style={styles.menumenu}
                  >
                    <Menu.Item name="partidas">
                      <a
                        href="#partidas"
                        className="menu-link menu-item"
                        onClick={this.handleScroll}
                      >
                        Partidas
                      </a>
                    </Menu.Item>
                    <Menu.Item name="vantagens">
                      <a
                        href="#regulamento"
                        className="menu-link menu-item"
                        onClick={this.handleScroll}
                      >
                        Regulamento
                      </a>
                    </Menu.Item>
                    <Menu.Item name="contato">
                      <a
                        href="#contato"
                        className="menu-link menu-item"
                        onClick={this.handleScroll}
                      >
                        Entre em Contato
                      </a>
                    </Menu.Item>
                  </Responsive>
                  <Responsive
                    as={Menu.Menu}
                    maxWidth={879}
                    position="right"
                    style={styles.menumenu}
                  >
                    <Menu.Item>
                      <span
                        className="menu-item-drawer menu-link"
                        onClick={this.drawer}
                      >
                        <Icon
                          name={!visible ? "bars" : "delete"}
                          size="large"
                        />
                      </span>
                    </Menu.Item>
                  </Responsive>
                </Menu>
              </div>
              {/* Drawer */}
              <Transition
                duration={{ show: 600, hide: 300 }}
                animation="fade down"
                size="huge"
                visible={visible}
              >
                <div style={styles.drawer} className="ui-drawer">
                  <div>
                    <a
                      href="#partidas"
                      className="menu-link menu-link-drawer"
                      onClick={this.handleScroll}
                    >
                      Partidas
                    </a>
                  </div>
                  <div>
                    <a
                      href="#regulamento"
                      className="menu-link menu-link-drawer"
                      onClick={this.handleScroll}
                    >
                      Regulamento
                    </a>
                  </div>
                  <div>
                    <a
                      href="#contato"
                      className="menu-link menu-link-drawer"
                      onClick={this.handleScroll}
                    >
                      Entre em Contato
                    </a>
                  </div>
                  <div style={{ margin: "15px 20px 20px 20px" }}>
                    {/* <Button
                      as={Link}
                      to="/login"
                      className="ui-btn-1 ui-btn-theme-1"
                      fluid
                    >
                      Entrar
                    </Button> */}
                  </div>
                </div>
              </Transition>
            </div>
          )}

          <Transition
            duration={{ show: 300, hide: 200 }}
            animation="fade"
            size="huge"
            visible={visible}
          >
            <div
              style={styles.bgDrawer}
              onClick={() => this.drawer({ tamanho: "300px" })}
            ></div>
          </Transition>
        </div>
      );
    }
  }
}

const styles = {
  menu: {
    boxShadow: "0px 1px 4px blue",
    backgroundColor: "#FFF",
    zIndex: "1000",
  },
  menumenu: {
    marginRight: "10px",
  },
  normalize: {
    paddingBottom: "100px",
  },
  drawer: {
    position: "fixed",
    width: "100%",
    right: "0px",
    top: "0px",
    zIndex: 999,
    paddingTop: "100px",
    backgroundColor: "#FFF",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    boxShadow: "0px 2px 4px #AAA",
  },
  bgDrawer: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,.0)",
    width: "100%",
    right: "0px",
    top: "54px",
    zIndex: 998,
    height: "100%",
  },
  activeItem: {
    backgroundColor: "rgba(238, 118, 254,.2)",
  },
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.user.token,
    usuario: { ...state.user.user },
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuHorizontal);
